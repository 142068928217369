import React from 'react'
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


class _Nav extends React.Component {
    state = { logout: false }

    onLogout = () => {
        this.props.handleLogout()
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark">
                <Container fluid>
                    <Navbar.Brand as={NavLink} to="/" >ADMIN | aleta.hr</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarNav" />
                    <Navbar.Collapse id="navbarNav">
                        <Nav className="me-auto">
                            <Nav.Link as={NavLink} to="/etim">Etim</Nav.Link>
                            <Nav.Link as={NavLink} to="/bmecat">Bmecat</Nav.Link>
                            <Nav.Link as={NavLink} to="/translate">Translate</Nav.Link>
                            <NavDropdown title="Assets">
                                <NavDropdown.Item as={NavLink} to="/assets">BMECat assets</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/get-assets">Download assets az zip</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={NavLink} to="/assets-create-360">Create asset - image360</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/assets-create-pdf">Create asset - document(pdf)</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/assets-create-image">Create asset - image</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/assets-create-all">Create asset - All</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/assets-360">List assets - image360</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/assets-pdfs">List assets - document</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/assets-images">List assets - image</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={NavLink} to="/watermark" >Watermark</NavDropdown.Item>
                                <NavDropdown.Divider />
                            </NavDropdown>
                            <Nav.Link as={NavLink} to="/fullproduct" className="nav-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Full products</span></Nav.Link>
                            {/* <Nav.Link as={NavLink} to="/test" className="nav-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Test</span></Nav.Link> */}
                            <Nav.Link as={NavLink} to="/metel" className="nav-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Metel</span></Nav.Link>
                            <Nav.Link as={NavLink} to="/sync" className="nav-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Sync</span></Nav.Link>
                            <Nav.Link as={NavLink} to="/checkProduct" className="nav-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Check products</span></Nav.Link>
                            <Nav.Link as={NavLink} to="/discountApi" className="nav-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Discount API</span></Nav.Link>
                            <Nav.Link as={NavLink} to="/parse-description" className="nav-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Parse description</span></Nav.Link>
                        </Nav>
                        {this.props.loggedIn && (
                            <form className="d-flex">
                                <button className="btn btn-outline-success" onClick={this.onLogout}>Logout</button>
                            </form>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar >
        )
    }
}

export default _Nav;