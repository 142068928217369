import * as React from 'react';
import axios from 'axios';

class TranslateForm extends React.Component {

    handleChange = (e) => {
        this.setState({ text: e.target.value });
    }

    onSubmit = async (e) => {
        try {
            const translatedText = await axios.post('https://hagejsz679.execute-api.eu-central-1.amazonaws.com/', {
                text: this.state.text
            }, {
                headers: { 'Content-Type': 'application/json' }
            })
            if (translatedText.data.TranslatedText) {
                console.log(translatedText)
                this.props.handleTranslate(translatedText.data.TranslatedText)
            }
            else {
                this.props.handleTranslate('')
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    render() {
        return (
            <React.Fragment>
                <hr />
                <form>
                    <div className="form-group">
                        <label htmlFor="inputText">Text to translate</label>
                        <textarea className="form-control" id="inputText" name="inputText" rows="2" onChange={this.handleChange} />
                    </div>
                </form>
                <button className="btn btn-primary mb-2" onClick={this.onSubmit}>Translate</button>
                <br />
                <br />
            </React.Fragment>
        )
    }
}

export default TranslateForm;