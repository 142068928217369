import React, { useState } from 'react'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import ProgressBar from 'react-bootstrap/ProgressBar'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { SpinViewer } from "@egjs/react-view360"

const API = 'https://gw5dj1hws2.execute-api.eu-central-1.amazonaws.com'
const assetsBaseUrl = 'https://07k16kgozh.execute-api.eu-central-1.amazonaws.com'


const AssetsCreate360 = (props) => {
    const { handleLoading, handleAlert } = props
    const [token, setToken] = useState('')
    const [files, setFiles] = useState([])
    const [productId, setProductId] = useState('')
    const [progress, setProgress] = useState(0)
    const [uploadResponse, setUploadResponse] = useState(null)
    const [progressBarVisible, setProgressBarVisible] = useState(false)
    const [preview360Image, setPreview360Image] = useState({})
    const [overwrite, setOverwrite] = useState(false)

    const meta = {
        source: 'adm.aleta.hr',
        user: localStorage.getItem('loggedInUser')
    }

    axios.interceptors.response.use(response => {
        return response
    }, async (error) => {
        if (error?.response?.status === 401) {
            console.log('Refreshing token')
            let token = await refreshToken()
            let config = error.config
            config.headers.Authorization = 'Bearer ' + token
            console.log('Returning new token')
            return axios(config)
        }
        return Promise.reject(error)
    })

    const refreshToken = () => new Promise(async (resolve, reject) => {
        let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
            }
        }).catch(err => {
            console.log(err)
            return reject()
        })

        if (!token) return reject('failed')
        setToken(token.data.access_token)
        return resolve(token.data.access_token)
    })

    const getSignedUrl = (bucket, fileName, fileType, action) => new Promise(async (resolve, reject) => {
        const { data: { data: signedUrl } } = await axios
            .post("https://30bevcw8tk.execute-api.eu-central-1.amazonaws.com/getSignedUrl", {
                bucket: bucket,
                file: fileName,
                contentType: fileType,
                action: action
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .catch(err => {
                console.log(err)
                return { data: {} }
            })

        if (!signedUrl) return reject('Error getting signed url')
        return resolve(signedUrl)
    })

    const uploadFile = (signedUrl, file) => {
        var options = {
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted)
            },
            headers: {
                'Content-Type': file['type']
            }
        }

        return axios.put(signedUrl, file, options)
    }

    const onFileChange = async (e) => {
        console.log(e.target.files[0])
        setFiles(e.target.files[0])
        // const file = await convertBase64(e.target.files[0])
        // setFiles(file)
    }

    const onSubmit = () => new Promise(async (resolve, reject) => {
        handleLoading(true)
        setPreview360Image({})
        if (!productId) {
            handleAlert(false, 'You need to provide productId')
            handleLoading(false)
            return resolve(true)
        }
        setProgressBarVisible(true)
        console.log(productId)
        const [assetType, bmecatCode] = ['image360', 'MD46']
        const options = {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }

        const signedUrl = await getSignedUrl('image360-raw', `${files.name}`, files.type, 'putObject')
        console.log('signedUrl', signedUrl)

        await uploadFile(signedUrl, files)


        const { data: resp } = await axios.post(assetsBaseUrl + '/uploadAsset', { assetType, bmecatCode, meta, entityId: productId, assetPath: `image360-raw/${files.name}`, overwrite }, options).catch(err => {
            console.log(err)
            handleAlert(false, err.response.data.message || 'Error uploading and creating asset')
            return {}
        })

        if (!resp) {
            handleLoading(false)
            return resolve('Error')
        }

        resp.data?.watermarkedImagePath && (setPreview360Image({ src: 'https://assets.aleta.hr/' + resp.data.watermarkedImagePath.split('/')[1], count: resp.data.imageCount }))

        handleAlert(true, 'Successfuly uploaded asset')

        setUploadResponse(resp)
        handleLoading(false)
        setTimeout(() => {
            setProgressBarVisible(false)
        }, 10000);

        return resolve(true)
    })

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                return resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                return reject(error)
            }
        })
    }


    React.useEffect(() => {
        const getAccessToken = () => new Promise(async (resolve, reject) => {
            let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
                }
            }).catch(err => {
                console.log(err)
                return reject('failed')
            })

            if (!token) return reject('failed')
            setToken(token.data.access_token)
            resolve(token.data.access_token)
        })

        getAccessToken()
    }, [])

    const previewLink = `https://assets.aleta.hr/${uploadResponse && uploadResponse.data.assetPath.split('/')[1]}`

    return (
        <>
            <Row className="justify-content-md-center mt-2">
                <Col xs={12} md={6}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Label>Zip file containing images for 360 view.</Form.Label>
                        <Form.Control type="file" multiple onChange={onFileChange} />
                    </Form.Group>
                    <FloatingLabel
                        label="BC product No"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="productId"
                            placeholder='BC product No'
                            onChange={(e) => setProductId(e.target.value)}
                        />
                    </FloatingLabel>
                    <ToggleButton
                        id="forceCheck"
                        type="checkbox"
                        variant="outline-warning"
                        checked={overwrite}
                        onChange={(e) => setOverwrite(e.target.checked)}
                    >
                        Overwrite if already exist
                    </ToggleButton>
                    <br /><br />
                    <Button variant="primary" onClick={onSubmit}>
                        Upload
                    </Button>
                    {progressBarVisible && (
                        <>
                            <br /><br />
                            <ProgressBar now={progress} label={`${progress}%`} />
                        </>
                    )}
                    {uploadResponse && (
                        <>
                            <br /><br />
                            <strong>Upload response:</strong> <br />
                            <strong>Success:</strong> {uploadResponse.success.toString()} <br />
                            <strong>Data:</strong> {JSON.stringify(uploadResponse.data)}
                        </>
                    )}
                </Col>
                <Col xs={12} md={3} >
                    Preview:
                    <div>
                        {preview360Image.src && (
                            <>
                                <img style={{ width: '20%', margin: 'auto', marginBottom: '-10%', zIndex: 1000000, position: 'relative' }} src="watermark.webp" alt=""></img>
                                <SpinViewer
                                    tag="div"
                                    imageUrl={preview360Image.src}
                                    rowCount={preview360Image.count}
                                    scale={3}
                                />
                                <br /><br />
                                Asset bez watermarka: <br />
                                <Button variant="link" href={previewLink} >
                                    https://assets.aleta.hr/{uploadResponse && uploadResponse.data.assetPath.split('/')[1]}
                                </Button>
                            </>
                        )}
                    </div>
                </Col>
            </Row >
        </>
    )

}

export default AssetsCreate360