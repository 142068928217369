import * as React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

const loginUrl = 'https://cp5twslwuf.execute-api.eu-central-1.amazonaws.com/login'


class Login extends React.Component {
    state = {
        username: '',
        password: '',
        redirect: false
    }

    onClick = async (e) => {
        e.preventDefault()
        // LOCAL TESTING
        // if (this.state.username !== '' && this.state.password !== '') {
        //     localStorage.setItem('loggedIn', 'true')
        //     localStorage.setItem('loggedInUser', this.state.username)
        //     localStorage.setItem('expires', Date.now() + 3600000)
        //     this.props.handleLogin()
        //     this.setState({ redirect: true })
        // }

        if (this.state.username !== '' && this.state.password !== '') {
            this.props.handleLoading(true)
            try {
                const response = await axios.post(loginUrl, { username: this.state.username, password: this.state.password, service: 'login' })
                if (response.data.success) {
                    localStorage.setItem('loggedIn', 'true')
                    localStorage.setItem('loggedInUser', this.state.username)
                    localStorage.setItem('expires', Date.now() + 3600000)
                    this.props.handleLogin()
                    this.props.handleLoading(false)
                    this.props.handleAlert(true, 'Success login')
                    this.setState({ redirect: true })
                }
                else {
                    this.props.handleAlert(false, 'Wrong username or password')
                    this.props.handleLoading(false)
                }
            }
            catch (err) {
                console.log(err)
                this.props.handleLoading(false)
            }
        }

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        if (this.state.redirect === true) return (<Redirect to='/assets' />)
        return (
            <React.Fragment>
                <div className="row justify-content-center mt-5 pt-5">
                    <div className="col-sm-4">
                        <form>
                            <div className="form-group mb-3">
                                <label htmlFor="username">Username</label>
                                <input type="text" className="form-control" id="username" name="username" placeholder="Username" onChange={this.handleChange} />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="password">Password</label>
                                <input type="password" className="form-control" id="password" name="password" placeholder="Password" onChange={this.handleChange} />
                            </div>
                            <button className="btn btn-primary" onClick={this.onClick}>Login</button>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Login