import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ component: Comp, ...rest }) => {

    const getAuth = () => {
        if (!!localStorage.getItem('loggedIn') && localStorage.getItem('expires') > Date.now()) {
            localStorage.setItem('expires', Date.now() + (3600000 * 3))
            return true
        }
        else {
            rest.handleLogout()
            localStorage.removeItem('expires')
            localStorage.removeItem('loggedIn')
            localStorage.removeItem('loggedInUser')
            return false
        }
    }

    return (
        <Route
            {...rest}
            render={props =>
                getAuth() ? (
                    <Comp {...props} {...rest} />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />
    )
}

export default PrivateRoute