import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import Pagination from 'react-bootstrap/Pagination'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const API = 'https://gw5dj1hws2.execute-api.eu-central-1.amazonaws.com/parse'

const getToken = () => new Promise(async (resolve, reject) => {
    let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
        }
    }).catch(err => {
        console.log(err)
        return reject()
    })

    if (!token) return reject('failed')
    return resolve(token.data.access_token)
})

var token

const axiosParse = axios.create()

axiosParse.interceptors.response.use(response => {
    return response;
}, async (error) => {
    if (error?.response?.status === 401) {
        console.log('Refreshing token')
        token = await getToken()
        let config = error.config
        config.headers.Authorization = 'Bearer ' + token
        console.log('Returning new token')
        return axiosParse(config)
    }
    return Promise.reject(error)
});

const ParseDescription = (props) => {
    const { handleLoading, handleAlert } = props
    const [productId, setProductId] = React.useState('')
    const [text, setText] = React.useState('')
    const [textResp, setTextResp] = React.useState({})
    const [token, setToken] = useState('')

    React.useEffect(() => {
        const getAccessToken = () => new Promise(async (resolve, reject) => {
            let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
                }
            }).catch(err => {
                console.log(err)
                return reject('failed')
            })

            if (!token) return reject('failed')
            setToken(token.data.access_token)
            resolve(token.data.access_token)
        })

        getAccessToken()

    }, [])

    const parse = async () => {
        if (!productId) return
        handleLoading(true)
        const { data: { data: resp } } = await axiosParse.post(API, {
            text,
            productId
        }, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).catch(err => {
            console.log(err)
            return { data: { data: {} } }
        })

        setTextResp(resp)
        handleLoading(false)
    }

    return (
        <>
            <Row className='justify-content-center' style={{ marginTop: 15 }}>
                <Col xs={12} md={4}>
                    <FloatingLabel
                        label="Product ID"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="id"
                            placeholder='Product ID'
                            value={productId}
                            onChange={e => setProductId(e.target.value)}
                            onKeyDown={e => e.key === "Enter" && parse()}
                            autoFocus
                        />
                    </FloatingLabel>
                    {/* <FloatingLabel
                        label="Input text"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="text"
                            placeholder='Input text'
                            value={text}
                            onChange={e => setText(e.target.value)}
                            onKeyDown={e => e.key === "Enter" && parse()}
                            autoFocus
                        />
                    </FloatingLabel> */}
                    <Button variant="primary" onClick={parse}>
                        Send
                    </Button>
                </Col>
                <hr style={{ marginTop: 15 }} />
            </Row>
            <Row className='justify-content-center'>
                <Col xs={12} md={4}>
                    <FloatingLabel
                        label="Input text (erp1 - erp2)"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="text"
                            placeholder='Input text (erp1 - erp2)'
                            value={textResp.text}
                            disabled
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Description"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="text"
                            placeholder='Description'
                            value={textResp.description || ''}
                            disabled
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label="MPN"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="text"
                            placeholder='MPN'
                            value={textResp.mpn || ''}
                            disabled
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Manufacturer type description"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="text"
                            placeholder='Manufacturer type description'
                            value={textResp.manufacturerTypeDescription || ''}
                            disabled
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label="EAN"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="text"
                            placeholder='EAN'
                            value={textResp.ean || ''}
                            disabled
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xs={12} md={4}>
                    {textResp && textResp.steps?.length && textResp.steps.map(line => {
                        return (<>{line}<br/><br/></>)
                    })}
                </Col>
            </Row>
        </>
    )
}

export default ParseDescription