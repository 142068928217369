import * as React from 'react';
import axios from 'axios'

class Test extends React.Component {
    state = { loading: false }
    getToken = () => new Promise(async (resolve, reject) => {
        let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
            }
        }).catch(err => {
            console.log(err)
            return null
        })

        if (!token) reject('failed')
        else resolve(token.data.access_token)
    })

    onSubmit = async (e) => {
        e.preventDefault()
        this.setState({
            loading: true
        })

        let access_token = await this.getToken()
        axios.get('https://07k16kgozh.execute-api.eu-central-1.amazonaws.com/getBucket', {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
            .then(resp => {
                this.setState({
                    loading: false
                })
                window.location.replace(resp.data.data)
            })
            .catch(err => {
                console.log(err.message)
                this.setState({
                    loading: false
                })
            })
    }

    render() {
        return (
            <React.Fragment>
                <div className="row justify-content-center mt-3 pt-3">
                    <div className="col-sm-4">
                        <form>
                            <hr />
                            <div className="d-grid gap-2">
                                <button className="btn btn-primary mb-2" onClick={this.onSubmit} disabled={this.state.loading}>Get assets from s3</button>
                            </div>
                        </form>
                    </div>
                </div>
                {this.state.loading && (
                    <div className="row justify-content-center mb-2">
                        <div className="spinner-border text-primary mt-3 ml-3" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
            </React.Fragment >
        )
    }
}

export default Test