import React, { useState } from 'react'
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Spinner from 'react-bootstrap/Spinner'

const API = 'https://gw5dj1hws2.execute-api.eu-central-1.amazonaws.com'

const Sync = props => {
    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(false)
    const [populateLoading, setPopulateLoading] = useState(false)
    const [manufacturersStatus, setManufacturersStatus] = useState(null)
    const [populateProductsScanRadio, setPopulateProductsScanRadio] = useState(1)
    const [populateProductsForceMerge, setPopulateProductsForceMerge] = useState(false)

    const { handleLoading, handleAlert, getPopulateStatus, populateStatus } = props

    React.useEffect(() => {
        const getAccessToken = () => new Promise(async (resolve, reject) => {
            let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
                }
            }).catch(err => {
                console.log(err)
                return reject('failed')
            })

            if (!token) return reject('failed')
            setToken(token.data.access_token)
            resolve(token.data.access_token)
        })

        getAccessToken()
    }, [])

    React.useEffect(() => {
        if (populateStatus) {
            setPopulateLoading(populateStatus.message === 'working')
        }
    }, [populateStatus])

    const refreshToken = () => new Promise(async (resolve, reject) => {
        let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
            }
        }).catch(err => {
            console.log(err)
            return reject()
        })

        if (!token) return reject('failed')
        setToken(token.data.access_token)
        return resolve(token.data.access_token)
    })


    const syncManufacturers = async (token) => {
        handleLoading(true)
        setLoading(true)
        let tok = token
        let refresh = false
        let resp = await axios.get(API + '/populateNavManufacturers', {
            headers: {
                Authorization: `Bearer ${tok}`
            }
        })
            .catch(async (err) => {
                console.log(err)
                if (err.response && err.response.status === 401) {
                    tok = await refreshToken()
                    refresh = true
                }
                return null
            })
        if (!resp) {
            if (refresh) {
                return syncManufacturers(tok)
            }
            setManufacturersStatus('Error syncing manufacturers')
        }
        else setManufacturersStatus({ total: resp.data.data.manufacturersNo, new: resp.data.data.new })
        setLoading(false)
        handleLoading(false)
        return
    }

    const populateProducts = async (token) => {
        handleLoading(true)
        setLoading(true)
        let tok = token
        let refresh = false
        let indexName = null
        let doubleScanFlag = false
        switch (populateProductsScanRadio) {
            case 1:
                indexName = 'mpn-manufacturerId-index'
                doubleScanFlag = true
                break
            case 2:
                indexName = 'ean-index'
                break
            case 3:
                indexName = 'mpn-manufacturerId-index'
                break
            default:
                break
        }
        let resp = await axios.post(API + '/populateProducts',
            { indexName, force: populateProductsForceMerge, doubleScanFlag },
            {
                headers: {
                    Authorization: `Bearer ${tok}`
                }
            })
            .catch(async (err) => {
                console.log(err)
                if (err.response && err.response.status === 401) {
                    tok = await refreshToken()
                    refresh = true
                }
                return null
            })
        if (!resp) {
            if (refresh) {
                return populateProducts(tok)
            }
            handleAlert(false, 'Error syncing products')
        }
        else {
            getPopulateStatus()
        }
        setLoading(false)
        handleLoading(false)
        return
    }

    return (
        <>
            <Row xs={1} md={2} lg={4} className='justify-content-center'>
                <Col className='my-5'>
                    <Card className="text-center">
                        <Card.Header as="h3" className='bg-primary text-light'>Manufacturers</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Sync NAV manufacturers to SCMP table
                            </Card.Text>
                            <div className="d-grid">
                                <Button variant="outline-primary" onClick={() => syncManufacturers(token)} disabled={loading}>Sync</Button>
                            </div>

                        </Card.Body>
                    </Card>
                    {manufacturersStatus && (
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><strong>NAV count:</strong> {manufacturersStatus.total || 0}</li>
                            <li className="list-group-item"><strong>New:</strong> {manufacturersStatus.new || 0}</li>
                        </ul>
                    )}
                </Col>
                <Col className='my-5'>
                    <Card className="text-center">
                        <Card.Header as="h3" className='bg-info'>Populate products</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Populate products with metel data <br />
                                Choose to scan all products or ean or mpn index. Default is all products.
                            </Card.Text>
                            <ToggleButtonGroup
                                type="radio"
                                name="options"
                                value={populateProductsScanRadio}
                                onChange={(val) => setPopulateProductsScanRadio(val)}
                            >
                                <ToggleButton id="tbg-radio-1" value={1} variant="outline-success">
                                    ALL
                                </ToggleButton>
                                <ToggleButton id="tbg-radio-2" value={2} variant="outline-success">
                                    ean
                                </ToggleButton>
                                <ToggleButton id="tbg-radio-3" value={3} variant="outline-success">
                                    mpn
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <hr />
                            <Card.Text>
                                Choose do you want to force merge.
                                No matter if data already exist it will overwrite it.<br />
                                It still checks mutation date and process only if metel date is newer than one saved in dynamo.
                            </Card.Text>
                            <ToggleButton
                                id="forceCheck"
                                type="checkbox"
                                variant="outline-primary"
                                checked={populateProductsForceMerge}
                                onChange={(e) => setPopulateProductsForceMerge(e.target.checked)}
                            >
                                Force merge
                            </ToggleButton>
                            <div className="d-grid my-3">
                                <Button variant="outline-info" onClick={() => populateProducts(token)} disabled={loading || populateLoading}>START</Button>
                            </div>

                        </Card.Body>
                    </Card>
                    {populateLoading && (<Spinner animation="grow" variant="danger" />)}
                    <span className="text-danger"><strong>{populateStatus && populateStatus.message}</strong></span>
                    {populateStatus && populateStatus.data && Object.keys(populateStatus.data).length > 0 && (
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><strong>Index:</strong> {populateStatus.data.indexName}</li>
                            <li className="list-group-item"><strong>Last processed key:</strong> {populateStatus.data.lastKey ? JSON.stringify(populateStatus.data.lastKey) : ''}</li>
                            <li className="list-group-item"><strong>Total processed:</strong> {populateStatus.data.countProcessed}</li>
                            <li className="list-group-item"><strong>Total skipped:</strong> {populateStatus.data.countSkipped}</li>
                            <li className="list-group-item"><strong>Total failed:</strong> {populateStatus.data.countFailed}</li>
                        </ul>
                    )}
                </Col>
            </Row>
        </ >
    )

}

export default Sync