import React, { useState } from 'react'
import axios from 'axios'
import { Fragment } from 'react/cjs/react.production.min'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import ReactJson from 'react-json-view'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'

const API = 'https://gw5dj1hws2.execute-api.eu-central-1.amazonaws.com'

const Metel = (props) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [searchEan, setSearchEan] = useState('')
    const [searchMpn, setSearchMpn] = useState('')
    const [searchDynamoProduct, setSearchDynamoProduct] = useState('')
    const [resultsNo, setResultsNo] = useState(null)
    const [manufacturersNo, setManufacturersNo] = useState(null)
    const [results, setResults] = useState([])
    const [paginatedResults, setPaginatedResults] = useState({})
    const [pageNumber, setPageNumber] = useState(1)
    const [selectedResult, setSelectedResult] = useState(null)
    const [selectedResultImg, setSelectedResultImg] = useState(null)
    const [dynamoProduct, setDynamoProduct] = useState(null)
    const [openDialog, setOpenDialog] = useState(false)
    const [openConfirmEan, setOpenConfirmEan] = useState(false)
    const [token, setToken] = useState('')
    const [mergeResponse, setMergeResponse] = useState(null)
    const [mergeStatus, setMergeStatus] = useState('')

    const { handleLoading } = props

    const meta = {
        source: 'adm.aleta.hr/metel',
        user: localStorage.getItem('loggedInUser')
    }

    const refreshToken = () => new Promise(async (resolve, reject) => {
        let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
            }
        }).catch(err => {
            console.log(err)
            return reject()
        })

        if (!token) return reject('failed')
        setToken(token.data.access_token)
        return resolve(token.data.access_token)
    })

    const getDynamoProduct = async (token) => {
        let tok = token
        let refresh = false
        let resp = await axios(API + '/getDynamoProduct/' + searchDynamoProduct, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).catch(async (err) => {
            console.log(err)
            if (err.response && err.response.status === 401) {
                tok = await refreshToken()
                refresh = true
            }
            return null
        })
        if (!resp) {
            if (refresh) {
                return getDynamoProduct(tok)
            }
            return null
        }
        return (resp.data.data)
    }

    React.useEffect(() => {
        const getAccessToken = () => new Promise(async (resolve, reject) => {
            let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
                }
            }).catch(err => {
                console.log(err)
                return reject('failed')
            })

            if (!token) return reject('failed')
            setToken(token.data.access_token)
            resolve(token.data.access_token)
        })

        getAccessToken()
    }, [])

    React.useEffect(() => {
        if (selectedResult && 'dati_immagini' in selectedResult && 'IT' in selectedResult.dati_immagini && selectedResult.dati_immagini.IT.length > 0) {
            let assets = selectedResult.dati_immagini.IT
            let productImg = ''
            assets = assets.filter(x => x.mime_code === 'MD01' && x.mime_source.toLowerCase().includes('http'))
                .map(x => {
                    if (x.mime_source.startsWith('.')) {
                        x.mime_source = x.mime_source.substring(1)
                    }
                    return x
                })
            if (assets.length > 0) {
                let tempAssets = assets.filter(x => x.aws_s3 !== null && x.aws_s3 !== 'NO_IMAGE')
                if (tempAssets.length > 0) { productImg = tempAssets[0].mime_source }
                else productImg = assets[0].mime_source
                setSelectedResultImg(productImg)
            }
        }
        setOpenDialog(selectedResult ? true : false)
    }, [selectedResult])

    const onDialogClose = () => {
        setSelectedResult(null)
        setSelectedResultImg(null)
        setDynamoProduct(null)
        setSearchDynamoProduct('')
        setMergeResponse(null)
        setMergeStatus(null)
    }

    const onConfirmEanClose = () => {
        setOpenConfirmEan(false)
    }

    const search = async (token) => {
        let tok = token
        let refresh = false
        if (searchEan.length === 0 && searchMpn.length === 0 && searchTerm.length === 0) { return }
        handleLoading(true)
        setResultsNo(null)
        setManufacturersNo(null)
        setResults([])

        let resp = await axios(API + (searchEan.length > 0 ? '/searchMetelByEan/' + searchEan : searchMpn.length > 0 ? '/searchMetelByMpn/' + encodeURIComponent(searchMpn) : '/searchMetel/1/' + encodeURIComponent(searchTerm)), {
            headers: {
                Authorization: `Bearer ${tok}`
            }
        }).catch(async (err) => {
            console.log(err)
            if (err.response && err.response.status === 401) {
                tok = await refreshToken()
                refresh = true
            }
            return null
        })
        if (!resp) {
            if (refresh) {
                search(tok)
                return
            }
            handleLoading(false)
            return
        }
        console.log(resp.data)
        setResultsNo(resp.data.data.totale_prodotti)
        setManufacturersNo(resp.data.data.totale_aziende)
        let results = resp.data.data.prodotti
        setPaginatedResults({ '1': results })
        setPageNumber(1)
        setResults(results)
        handleLoading(false)

    }

    const check = async (token) => {
        if (searchDynamoProduct && searchDynamoProduct.length === 0) { return }
        handleLoading(true)
        setDynamoProduct(null)
        setMergeResponse(null)
        setMergeStatus('')

        let prod = await getDynamoProduct(token)
        console.log(prod)
        if (!prod || prod.length === 0) {
            handleLoading(false)
            return
        }

        let gd = prod.filter(x => x.PK === x.SK)[0]
        let generalData = {}
        if (gd) {
            const { manufacturerId = '', manufacturerTypeDescription = '', ean = '', mpn = '', meta = {}, metelMerge = null } = gd
            generalData = {
                manufacturerId,
                metelMerge,
                manufacturerTypeDescription,
                ean,
                mpn,
                meta
            }
        }

        let etimVer = 'null'
        let ev = prod.filter(x => x.SK.includes('etimver'))[0]
        if (ev) { etimVer = ev.SK.split('#')[1] }

        let _class = 'null'
        let cl = prod.filter(x => x.SK.includes('class'))[0]
        if (cl) { _class = cl.SK.split('#')[1] }

        setDynamoProduct({ ...generalData, etimVer, class: _class })
        handleLoading(false)

    }

    const merge = async (token, confirm) => {
        confirm && setOpenConfirmEan(false)

        if (searchDynamoProduct && searchDynamoProduct.length === 0) { return }

        const confirmEan = selectedResult && selectedResult.ean && dynamoProduct && dynamoProduct.ean && dynamoProduct.ean !== selectedResult.ean

        if (confirmEan && !confirm) {
            setOpenConfirmEan(true)
            return
        }

        let tok = token
        let refresh = false

        handleLoading(true)

        let resp = await axios.post(API + '/metelMerge', { productId: searchDynamoProduct, metelProduct: selectedResult, meta }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).catch(async (err) => {
            console.log(err)
            if (err.response && err.response.status === 401) {
                tok = await refreshToken()
                refresh = true
            }
            return null
        })
        if (!resp) {
            if (refresh) {
                merge(tok, confirm)
                return
            }
            setMergeStatus('FAILED')
            handleLoading(false)
            return
        }
        console.log(resp.data)

        let prod = await getDynamoProduct(token)
        console.log(prod)
        if (!prod) {
            handleLoading(false)
            return
        }

        let gd = prod.filter(x => x.PK === x.SK)[0]
        let generalData = {}
        if (gd) {
            const { manufacturerId = '', manufacturerTypeDescription = '', ean = '', mpn = '', meta = {}, metelMerge = null } = gd
            generalData = {
                manufacturerId,
                metelMerge,
                manufacturerTypeDescription,
                ean,
                mpn,
                meta
            }
        }

        let etimVer = 'null'
        let ev = prod.filter(x => x.SK.includes('etimver'))[0]
        if (ev) { etimVer = ev.SK.split('#')[1] }

        let _class = 'null'
        let cl = prod.filter(x => x.SK.includes('class'))[0]
        if (cl) { _class = cl.SK.split('#')[1] }

        setDynamoProduct({ ...generalData, etimVer, class: _class })
        setMergeResponse(resp.data)
        setMergeStatus('SUCCESS')
        handleLoading(false)
    }

    const clear = async (token) => {
        handleLoading(true)
        setDynamoProduct(null)
        setMergeResponse(null)
        setMergeStatus('')

        let tok = token
        let refresh = false

        let resp = await axios.post(API + '/metelClearProduct/' + searchDynamoProduct, { meta }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).catch(async (err) => {
            console.log(err)
            if (err.response && err.response.status === 401) {
                tok = await refreshToken()
                refresh = true
            }
            return null
        })
        if (!resp) {
            if (refresh) {
                clear(tok)
                return
            }
            handleLoading(false)
            return
        }

        let prod = await getDynamoProduct(token)
        console.log(prod)
        if (!prod) {
            handleLoading(false)
            return
        }

        let gd = prod.filter(x => x.PK === x.SK)[0]
        let generalData = {}
        if (gd) {
            const { manufacturerId = '', manufacturerTypeDescription = '', ean = '', mpn = '', meta = {}, metelMerge = null } = gd
            generalData = {
                manufacturerId,
                metelMerge,
                manufacturerTypeDescription,
                ean,
                mpn,
                meta
            }
        }

        let etimVer = 'null'
        let ev = prod.filter(x => x.SK.includes('etimver'))[0]
        if (ev) { etimVer = ev.SK.split('#')[1] }

        let _class = 'null'
        let cl = prod.filter(x => x.SK.includes('class'))[0]
        if (cl) { _class = cl.SK.split('#')[1] }

        setDynamoProduct({ ...generalData, etimVer, class: _class })
        handleLoading(false)

    }

    const handleChangePage = async (page, token) => {
        handleLoading(true)
        console.log(paginatedResults)
        if (page in paginatedResults) {
            setResults(paginatedResults[page])
        }
        else {
            let tok = token
            let refresh = false
            let resp = await axios(API + '/searchMetel/' + page + '/' + encodeURIComponent(searchTerm), {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).catch(async (err) => {
                console.log(err)
                if (err.response && err.response.status === 401) {
                    tok = await refreshToken()
                    refresh = true
                }
                return null
            })
            if (!resp) {
                if (refresh) {
                    handleChangePage(page, tok)
                    return
                }
                handleLoading(false)
                return
            }

            setPaginatedResults(prev => ({ ...prev, [page]: resp.data.data.prodotti }))
            setResults(resp.data.data.prodotti)
        }
        setPageNumber(parseInt(page))
        handleLoading(false)
    }

    const handleRowClick = async (product, token) => {
        if (!product.ean || product.ean === '0000000000000') return
        handleLoading(true)
        let tok = token
        let refresh = false
        // get product full data
        let resp = await axios(API + '/searchMetelByEan/' + product.ean, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).catch(async (err) => {
            console.log(err)
            if (err.response && err.response.status === 401) {
                tok = await refreshToken()
                refresh = true

            }
            return null
        })
        if (!resp) {
            if (refresh) {
                handleRowClick(product, tok)
                return
            }
            handleLoading(false)
            return
        }
        console.log(resp.data)
        // save to new state
        if (resp.data.data.prodotti.length === 1) {
            handleLoading(false)
            setSelectedResult(resp.data.data.prodotti[0])
        }
        handleLoading(false)
        //console.log(product)
    }


    let pageNumbers = [];
    for (let number = 1; number <= parseInt(resultsNo) / 12 + 1 && number <= 10; number++) {
        pageNumbers.push(
            <Pagination.Item key={number} active={number === pageNumber} onClick={(e) => handleChangePage(e.target.textContent, token)}>
                {number}
            </Pagination.Item>
        )
    }

    return (
        <Fragment>
            <div className="row justify-content-center mt-2 pt-2">
                <div className="col-xs-12 col-sm-10 col-md-6 col-xl-4">
                    <Row>
                        <Col xs={6}>
                            <FloatingLabel
                                label="Search term"
                                className="mb-3"
                            >
                                <Form.Control
                                    type="text"
                                    id="search"
                                    placeholder='Search term'
                                    onChange={e => setSearchTerm(e.target.value)}
                                    onKeyDown={e => e.key === "Enter" && search(token)}
                                    autoFocus
                                />
                            </FloatingLabel>
                        </Col>
                        <Col xs={3}>
                            <FloatingLabel
                                label="EAN"
                                className="mb-3"
                            >
                                <Form.Control
                                    type="text"
                                    id="searchEan"
                                    placeholder='EAN'
                                    onChange={(e) => setSearchEan(e.target.value)}
                                    onKeyDown={e => e.key === "Enter" && search(token)}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col xs={3}>
                            <FloatingLabel
                                label="MPN"
                                className="mb-3"
                            >
                                <Form.Control
                                    type="text"
                                    id="searchMpn"
                                    placeholder='MPN'
                                    onChange={(e) => setSearchMpn(e.target.value)}
                                    onKeyDown={e => e.key === "Enter" && search(token)}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <div className="d-grid">
                        <Button onClick={() => search(token)} >Search</Button>
                    </div>
                </div>
            </div>
            {results && (
                <Fragment>
                    <div className="row justify-content-center mt-2 pt-2">
                        <div className="col-xs-12 col-sm-10 col-md-6 col-xl-4">
                            {resultsNo !== null && (<p><span style={{ fontWeight: 'bold' }}>Total products:</span> {resultsNo}</p>)}
                            {manufacturersNo !== null && (<p><span style={{ fontWeight: 'bold' }}>Total manufacturers:</span> {manufacturersNo}</p>)}
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2 pt-2">
                        <div className="col-lg-10">
                            <Pagination>{pageNumbers}</Pagination>
                            <div className='table-responsive'>
                                <table className="table table-sm table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Manufacturer</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">EAN</th>
                                            <th scope="col">MPN</th>
                                            <th scope="col">Etim version</th>
                                            <th scope="col">Class</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {results.map(result => (
                                            <tr key={(results.indexOf(result) + 1) + result.ean + result.codice_prodotto_azienda} onClick={() => handleRowClick(result, token)}>
                                                <th scope="row">{(results.indexOf(result) + 1) + pageNumber * 12 - 12}</th>
                                                <td>{result.rag_soc}</td>
                                                <td>{result.descrizione_prodotto}</td>
                                                <td>{result.ean}</td>
                                                <td>{result.codice_prodotto_azienda}</td>
                                                <td>{result.versione_etim}</td>
                                                <td>{result.classe_etim}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
            {selectedResult && (
                <>
                    <Modal show={openDialog && !openConfirmEan} onHide={onDialogClose} fullscreen={'lg-down'} animation={false} size="lg" centered>
                        <Modal.Header closeButton className='bg-info '>
                            <Modal.Title>Merge with dynamo product</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={9}>
                                        <Row>
                                            <Col>
                                                <FloatingLabel
                                                    controlId="searchNav"
                                                    label="NAV Product ID"
                                                    className="mb-3"
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="1016132"
                                                        value={searchDynamoProduct}
                                                        onChange={(e) => setSearchDynamoProduct(e.target.value)}
                                                        onKeyDown={e => e.key === "Enter" && check(token)}
                                                        autoFocus
                                                    />
                                                </FloatingLabel>
                                                <Button size={'sm'} variant="outline-success" onClick={() => check(token)}>
                                                    Check
                                                </Button>
                                                {
                                                    dynamoProduct && dynamoProduct.metelMerge && (
                                                        <Button size={'sm'} className='float-end' variant="outline-danger" onClick={() => clear(token)}>
                                                            Clear metel data from product
                                                        </Button>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        {dynamoProduct && (
                                            <>
                                                <hr />
                                                <Row>
                                                    <Col>
                                                        {
                                                            Object.keys(dynamoProduct).filter(x => x !== 'metelMerge').map(x =>
                                                            (
                                                                <div>
                                                                    <span style={{ fontWeight: 'bold' }}>{x}: </span>
                                                                    {typeof (dynamoProduct[x]) === 'object' ? JSON.stringify(dynamoProduct[x]) : dynamoProduct[x]}
                                                                </div>
                                                            )
                                                            )
                                                        }
                                                        {
                                                            dynamoProduct.metelMerge && (
                                                                <>
                                                                    <div>
                                                                        <span className='text-danger fw-bold'>* This product has already been merged by {dynamoProduct.metelMerge.user} at {new Date(dynamoProduct.metelMerge.updated).toLocaleString('en-GB')}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className='text-danger fw-bold'>* {dynamoProduct.metelMerge.metelDataMutationDate ? 'Metel mutation date: ' + new Date(dynamoProduct.metelMerge.metelDataMutationDate).toLocaleString('en-GB') : 'No metel mutation date in metelMerge atribute'}</span>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        {mergeStatus && (
                                                            <>
                                                                <Row>
                                                                    <Col>
                                                                        <Badge bg={mergeStatus === 'SUCCESS' ? 'success' : 'danger'}>{mergeStatus}</Badge>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Col>
                                    <Col xs={3}>
                                        {selectedResultImg && (
                                            <Card src={selectedResultImg}>
                                                <Card.Img src={selectedResultImg} />
                                            </Card>
                                        )}
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col xs={12}>
                                        <Button variant="primary" onClick={() => merge(token, false)} className={'float-end'} disabled={!dynamoProduct || (dynamoProduct && dynamoProduct.metelMerge)}>
                                            Merge
                                        </Button>
                                        <h5>{mergeResponse ? 'Merge results:' : 'Metel product:'}</h5>
                                        <ReactJson
                                            src={mergeResponse || selectedResult}
                                            collapsed={1}
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                            displayArrayKey={false}
                                            enableClipboard={false}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={onDialogClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {dynamoProduct && (
                        <Modal
                            show={openConfirmEan}
                            onHide={onConfirmEanClose}
                            fullscreen={'lg-down'}
                            animation={false}
                            size="lg"
                            centered
                        >
                            <Modal.Header closeButton className='bg-info'>
                                <Modal.Title>Overwrite EAN and MPN?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p style={{ fontWeight: 'bold' }}>
                                    Do you want to merge these products with different EANs???
                                </p>
                                <p>
                                    Dynamo EAN: {dynamoProduct.ean || ''}
                                    <br></br>
                                    Metel EAN: {selectedResult.ean || ''}
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary me-3" onClick={() => merge(token, true)}>
                                    Yes
                                </Button>
                                <Button variant="primary" onClick={onConfirmEanClose}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </>
            )
            }

        </Fragment >
    )
}

export default Metel