import * as React from 'react'
import axios from 'axios'

const populateUrl = 'https://36oz17shqk.execute-api.eu-central-1.amazonaws.com/populate'

class Bmecat extends React.Component {

    onSubmit = async (e) => {
        return new Promise(async (resolve, reject) => {
            e.preventDefault()
            try {
                await axios.get(populateUrl)
                this.props.handleGetEtimStatus()
                resolve()
            }
            catch (err) {
                console.log(err)
                reject(err)
            }
        })
    }

    componentDidMount = () => {

    }

    render() {
        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <div className="col-sm-4">
                        <hr />
                        <div className="d-grid gap-2">
                            <button className="btn btn-primary mb-2" onClick={this.onSubmit} disabled={this.props.data.loading}>Get All</button>
                        </div>
                    </div>
                </div>
                {this.props.data.loading && (
                    <div className="row justify-content-center mb-2">
                        <div className="spinner-border text-primary mt-3 ml-3" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {this.props.data.message && (
                    <div className="row justify-content-center mb-2">
                        <div>
                            {this.props.data.message}
                        </div>
                    </div>
                )}

                {this.props.data.data.length > 0 && (
                    <div className="row justify-content-center">
                        <div className="col-sm-6">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Type</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Available</th>
                                        <th scope="col">Written</th>
                                        <th scope="col">Consumed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.data.data.map(entry => {
                                        return (
                                            <tr>
                                                <td>{entry.type}</td>
                                                <td>{entry.status}</td>
                                                <td>{entry.available}</td>
                                                <td>{entry.written}</td>
                                                <td>{entry.consumed}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </React.Fragment >
        )
    }
}

export default Bmecat