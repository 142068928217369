import * as React from 'react';
import axios from 'axios';
import { Table, Spin } from 'antd'
import 'antd/dist/antd.css';

const translationUrl = 'https://r82tf484b4.execute-api.eu-central-1.amazonaws.com/translation'

class TableData extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], changedRows: [], loading: false, filterField: 'id', filterText: '', searchField: 'id', searchText: '', editingId: '', editingType: '', editedText: '' };
        this.filterText = React.createRef();
        this.searchText = React.createRef();
        this.editText = React.createRef();
    }

    _isMounted = false

    componentDidMount = async () => {
        this._isMounted = true
        try {
            this.setState({ loading: true })

            const data = await axios.get(translationUrl)
            if (this._isMounted) {
                this.setState({
                    data: data.data,
                    filteredData: data.data,
                    loading: false
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false
    }

    onCancelEdit = (record) => {
        this.setState({
            editingId: '',
            editingType: ''
        })
    }

    onEditRow = (row) => {
        this.setState({
            editingId: row.id,
            editingType: row.type,
            editedText: row.translatedText
        })
    }

    onSaveRow = async (row) => {
        try {
            const resp = await axios.put(translationUrl, {
                id: row.id,
                type: row.type,
                translatedText: this.state.editedText
            })

            if (resp.data.changedRows < 1) return
        }
        catch (err) {
            console.log(err)
            return
        }

        // Change data inline, no db query after save (if db save successful)
        let tempData = this.state.data.map((item) => {
            if (item.id === row.id && item.type === row.type) {
                item.translatedText = this.state.editedText
                item.userChanged = 1
            }
            return item
        })

        this.setState({
            data: tempData,
            filteredData: tempData,
            editingId: '',
            editingType: ''
        })

        // Restore filter after save edited row
        let newData = this.state.data.filter((item) => {
            return item[this.state.filterField].includes(this.state.filterText)
        })
        this.setState({
            filteredData: newData
        })
    }

    onEditTextChange = (e) => {
        this.setState({
            editedText: e.target.value
        })
    }

    onFilterTextChange = (e) => {
        this.setState({
            filterText: e.target.value
        })
        let newData = this.state.data.filter((item) => {
            return item[this.state.filterField].includes(e.target.value)
        })
        this.setState({
            filteredData: newData
        })
    }

    onSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    onFilterFieldChange = (e) => {
        this.setState({ filterField: e.target.value })
    }

    onSearchFieldChange = (e) => {
        this.setState({ searchField: e.target.value })
    }

    onClearFilter = (e) => {
        e.preventDefault()
        this.filterText.current.value = ''
        this.setState({
            filteredData: this.state.data
        })
    }

    onSearch = async (e) => {
        e.preventDefault()
        let url = ''
        this.setState({
            loading: true
        })
        if (this.searchText.current.value !== '') {
            switch (this.state.searchField) {
                case 'id':
                    url = '/id/' + this.state.searchText
                    break
                case 'type':
                    url = '/type/' + this.state.searchText
                    break
                case 'text':
                    url = '/text/' + this.state.searchText
                    break
                default:
                    console.log('Something is wrong')
            }
        }
        try {
            const searchResult = await axios.get(translationUrl + url)
            this.setState({
                data: searchResult.data,
                filteredData: searchResult.data,
            })
        }
        catch (err) {
            console.log(err)
        }
        this.setState({ loading: false })
    }

    render() {
        const columns = [
            {
                title: 'Id',
                dataIndex: 'id'
            },
            {
                title: 'Type',
                dataIndex: 'type'
            },
            {
                title: 'Text',
                dataIndex: 'text'
            },
            {
                title: 'Translated text',
                dataIndex: 'translatedText',
                render: (text, record) =>
                    (record.id === this.state.editingId && record.type === this.state.editingType) ? (
                        <input ref={this.editText} type="text" className="form-control" defaultValue={text} onChange={this.onEditTextChange} />
                    ) : (
                            text
                        )
            },
            {
                title: 'User Changed',
                dataIndex: 'userChanged'
            },
            {
                title: "Action",
                render: (text, record) => (
                    (record.id === this.state.editingId && record.type === this.state.editingType) ? (
                        <div>
                            <button type="button" className="btn btn-warning btn-sm" onClickCapture={() => this.onSaveRow(record)}>SAVE</button>
                            <button type="button" className="btn btn-link btn-sm" onClickCapture={() => this.onCancelEdit(record)}>Cancel</button>
                        </div>
                    ) : (
                            <button type="button" className="btn btn-link" onClickCapture={() => this.onEditRow(record)}><i className="far fa-edit"></i></button>
                        )
                ),
                fixed: 'right',
                width: 130
            }
        ]

        return (
            <React.Fragment>
                <div className='row justify-content-center'>
                    <div className='col-sm-6'>
                        <div className="card border-warning">
                            <div className="card-header bg-warning">
                                Search Database
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="form-row">
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="searchText">Search term</label>
                                            <input ref={this.searchText} type='text' id='searchText' className='form-control' onChange={this.onSearchTextChange} />
                                        </div>
                                        <div className="col mb-3">
                                            <label htmlFor="searchField">Search field</label>
                                            <select id='searchField' className='form-control' onChange={this.onSearchFieldChange} defaultValue='id'>
                                                <option value='id'>Id</option>
                                                <option value='type'>Type</option>
                                                <option value='text'>Text</option>
                                            </select>
                                        </div>
                                        <div className="col align-self-end">
                                            <button className='btn btn-primary' onClick={this.onSearch}>Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <form>
                    <div className="row">
                        <div className="col col-sm-2">
                            <label htmlFor="filterText">Filter term</label>
                            <input ref={this.filterText} type='text' id='filterText' className='form-control' onChange={this.onFilterTextChange} />
                        </div>
                        <div className="col col-sm-1">
                            <label htmlFor="filterField">Filter field</label>
                            <select id='filterField' className='form-control' onChange={this.onFilterFieldChange} defaultValue='id'>
                                <option value='id'>Id</option>
                                <option value='type'>Type</option>
                                <option value='text'>Text</option>
                            </select>
                        </div>
                        <div className="col align-self-end">
                            <button className='btn btn-link' onClick={this.onClearFilter}>Clear Filter</button>
                        </div>
                    </div>
                </form>
                <hr />
                <Spin spinning={this.state.loading} size='large'>
                    <Table columns={columns} dataSource={this.state.filteredData} rowKey={record => record.id + record.type} size="small" />
                    <hr />
                    <button className="btn btn-primary" onClick={this.onSave}>Save</button>
                </Spin>
            </React.Fragment >
        )
    }
}

export default TableData;