import * as React from 'react';
import axios from 'axios'

class Test extends React.Component {
    onSubmit = async (e) => {
        e.preventDefault()
        let access_token = 'eyJraWQiOiJnYnpFQzQxMkdLK1hpbmNTMk9SVFRZUDJrSU44VkJKN1o5QWwwakNZcHB3PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJkYzkxNzIzYy1kYTNiLTQ0MTEtOWEwYi1iODlhMGQ2MTY5MjIiLCJldmVudF9pZCI6Ijg1NTFkNjlkLWJhMTktNDBiYi1iOTU4LTZmZDNlMGEyYTk4NiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MjA3MTkwMjQsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX0FPWllZWXdQbiIsImV4cCI6MTYyMDcyMjYyNiwiaWF0IjoxNjIwNzE5MDI2LCJqdGkiOiIwZWU3NmVjMC01OTdjLTRhZmItYWM1OS03NWNkMWFhNDE3YTgiLCJjbGllbnRfaWQiOiJpZjl1Z2VrbG9qZWViOW9hN2VrNWo5ZnI0IiwidXNlcm5hbWUiOiJkYzkxNzIzYy1kYTNiLTQ0MTEtOWEwYi1iODlhMGQ2MTY5MjIifQ.ZsQuZrTra-jc_t4Ftw094eVJOC1NWnHIEj3kQUvHADbnnXKUQLMNT6Zm7o_rLLyrySQA_CosB8LLJwu02jM2uC_FBbq80W-jXvts0MawpY2moCHNsa_t2xEgcXycjPDHUn9uD_n3eWBhnXF2u39JiYGzwkMUlc40cv-sQOhCxIIaAhOg8YW26Dhz3HN6V6MeDOZN9azMtpOrkIoZT9eRDSDVYjKw2H9jXOCwRYfLj5r0rgSH7tlzyxS4un59j4bfB96iBqIKaSOmozfxnDCHyA4rP3Hubkgj32VW4EcqY0M8sR8g0doqls3OsRjjLsQVQbE3aj-qqU35HqzsKUqGSw'

        axios.get('https://cognis.aleta.hr/kartica-kupca?customerNo=K-23393', {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
            .then(resp => {
                console.log(resp.data)
            })
            .catch(err => { console.log(err.message) })
    }

    render() {
        return (
            <React.Fragment>
                <form>
                    <button className="btn btn-primary mb-2" onClick={this.onSubmit} >Test CORS!</button>
                </form>
            </React.Fragment>
        )
    }
}

export default Test