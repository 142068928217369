import * as React from 'react';

class TranslateResults extends React.Component {
    render() {
        return (
            <React.Fragment>
                <hr />
                <form>
                    <div className="form-group">
                        <label htmlFor="outputText">Translated text</label>
                        <textarea className="form-control" id="outputText" name="outputText" rows="2" value={this.props.data} readOnly />
                    </div>
                </form>
                <br />
                <br />
            </React.Fragment>
        )
    }
}

export default TranslateResults;