import * as React from 'react';
import BmecatForm from './BmecatForm';

class Bmecat extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <div className="col-sm-4">
                        <BmecatForm manufacturers={this.props.manufacturers}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Bmecat