import * as React from 'react'
import axios from 'axios'
import languages from '../languages.json'

const bmecatApi = 'https://g3re91yr6h.execute-api.eu-central-1.amazonaws.com/bmecat'

class BmecatForm extends React.Component {
    state = {
        file: {},
        progress: 0,
        showAlertSuccess: false,
        showAlertError: false,
        statusMessage: '',
        message: '',
        downloadUrls: [],
        finished: false,
        loading: false,
        selectedManufacturer: '',
        dataLanguage: languages.sort(function (a, b) {
            var x = a['English'].toLowerCase();
            var y = b['English'].toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        }),
        selectedLanguage: '',
        counterMessage: []
    }

    getSignedUrl = (bucket, fileName, action) => {
        return new Promise((resolve, reject) => {
            axios.post("https://30bevcw8tk.execute-api.eu-central-1.amazonaws.com/getSignedUrl", {
                bucket: bucket,
                file: fileName,
                action: action
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    uploadFile = (signedUrl, file) => {
        return new Promise((resolve, reject) => {
            var options = {
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({
                        progress: percentCompleted
                    })
                },
                headers: {
                    'Content-Type': file['type']
                }
            };

            axios.put(signedUrl, file, options)
                .then(response => {
                    resolve('')
                })
                .catch(err => {
                    reject(err.message);
                })
        })
    }

    generateFile = (bucket, fileName) => {
        return new Promise((resolve, reject) => {
            axios.post(bmecatApi + '/generate', {
                bucket: bucket,
                file: fileName,
                manufacturer: this.state.selectedManufacturer,
                lang: this.state.selectedLanguage
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve('');
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    getCounters = () => {
        return new Promise((resolve, reject) => {
            axios.post(bmecatApi + '/getParameters', {
                parameters: ['/bmecat/lastDDBCapacityUsed']
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(JSON.parse(response.data.data[0]))
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    getStatusMessage = () => {
        return new Promise((resolve, reject) => {
            axios.post(bmecatApi + '/getParameters', {
                parameters: ['/bmecat/statusMessage']
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response.data.data[0])
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    // translateFile = (bucket, prefix, fileName) => {
    //     return new Promise((resolve, reject) => {
    //         axios.post(bmecatApi + '/translate', {
    //             bucket: bucket,
    //             prefix: prefix,
    //             file: fileName
    //         }, {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //             .then(response => {
    //                 resolve('');
    //             })
    //             .catch((err) => {
    //                 reject(err);
    //             })
    //     })
    // }

    // checkTranslatedFile = (fileName) => {
    //     return new Promise((resolve, reject) => {
    //         axios.get(bmecatApi + '/translate')
    //             .then(response => {
    //                 //console.log(response.data);
    //                 if (response.data.includes(fileName)) { resolve(true) }
    //                 else resolve(false);
    //             })
    //             .catch(err => {
    //                 resolve(false);
    //             })
    //     })
    // }

    // checkFileWithEtim = (fileName) => {
    //     return new Promise((resolve, reject) => {
    //         axios.get(bmecatApi + '/populateEtim')
    //             .then(response => {
    //                 //console.log(response.data);
    //                 if (response.data.includes(fileName)) { resolve(true) }
    //                 else resolve(false);
    //             })
    //             .catch(err => {
    //                 resolve(false);
    //             })
    //     })
    // }

    // getRandomFileFromS3Folder = (folder) => {
    //     return new Promise((resolve, reject) => {
    //         axios.get(bmecatApi + '/populateEtim')
    //             .then(response => {
    //                 const files = response.data.filter(item => item.includes(folder) && item.includes('.json'))
    //                 const file = files[Math.floor(Math.random() * files.length)].split('/')[1]
    //                 console.log(file)
    //                 resolve(file)
    //             })
    //             .catch(err => {
    //                 console.log(err)
    //                 resolve('false');
    //             })
    //     })
    // }

    // readJson = (bucket, file) => {
    //     axios.post(bmecatApi + '/getJson', {
    //         bucket: bucket,
    //         file: file
    //     }, {
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //         .then(response => {
    //             if ('status' in response.data && response.data.status === 'success') {
    //                 this.showResult(response.data.file);
    //             }
    //         })
    //         .catch((err) => {
    //             this.setState({
    //                 loading: false,
    //                 statusMessage: ''
    //             })
    //             console.log(err);
    //         })
    // }

    showResult = (data) => {
        window.open("data:application/json," + encodeURIComponent(data),
            "_blank");
        this.setState({
            loading: false,
            statusMessage: ''
        })
    }

    checkResult = (fileName) => {
        return new Promise((resolve, reject) => {
            axios.get(bmecatApi + '/check')
                .then(response => {
                    //console.log(response.data);
                    if (response.data.includes(fileName)) { resolve(true) }
                    else resolve(false);
                })
                .catch(err => {
                    resolve(false);
                })
        })
    }

    showMessage = (success, message) => {
        if (success) {
            this.setState({ showAlertSuccess: true, message }, () => {
                window.setTimeout(() => {
                    this.setState({ showAlertSuccess: false, message: '' })
                }, 5000)
            });
        }
        else {
            this.setState({ showAlertError: true, message }, () => {
                window.setTimeout(() => {
                    this.setState({ showAlertError: false, message: '' })
                }, 5000)
            });
        }
    }

    handleChange = (e) => {
        this.setState({ file: e.target.files[0] });
    }

    onSubmit = async (e) => {
        e.preventDefault()
        this.setState({
            loading: false,
            statusMessage: '',
            counterMessage: '',
            message: '',
            downloadUrls: []
        })
        if (this.state.file === '' || this.state.selectedManufacturer === '' || this.state.selectedLanguage === '') {
            this.showMessage(false, 'You need to select all fields');
            return
        }
        const file = this.state.file;
        var signedUrl;

        // GET Signed url for file to upload
        try {
            this.setState({
                loading: false,
                statusMessage: 'Getting signed URL ...'
            })
            signedUrl = await this.getSignedUrl('bmecat-xml', file['name'], 'putObject');
        }
        catch (err) {
            console.log(err);
            this.showMessage(false, 'Error with getting signed url');
            return;
        }

        // Upload file
        try {
            this.setState({
                loading: false,
                statusMessage: 'Uploading file ...'
            })
            await this.uploadFile(signedUrl, file);
        }
        catch (err) {
            console.log(err);
            this.showMessage(false, 'Error with uploading file');
            return;
        }

        // Generate JSON
        try {
            this.setState({
                loading: true,
                statusMessage: 'Generating json ...'
            })
            await this.generateFile('bmecat-xml', file['name']);
        }
        catch (err) {
            console.log(err);
            this.showMessage(false, 'Error with generating JSON');
            return;
        }

        // Repeatidly check for if expected file exists in S3 for number of seconds - waiting generate to finish, and check status message for errors
        const sleep = ms => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }
        let waitingSeconds = 900;
        for (let i = 0; i < waitingSeconds; i++) {
            if (i === waitingSeconds - 1) {
                this.setState({
                    loading: false,
                    statusMessage: 'Unsuccessful with generating JSON file in given time: ' + waitingSeconds + ' seconds'
                })
                this.showMessage(false, 'Unsuccessful with generating JSON file in given time: ' + waitingSeconds + ' seconds');
                return;
            }
            try {
                // Get S3 files and status message
                const statusMessage = await this.getStatusMessage();
                console.log(statusMessage)
                if (statusMessage !== '0') {
                    this.showMessage(false, statusMessage)
                    this.setState({
                        loading: false,
                        statusMessage: statusMessage,
                    })
                    return
                }
                const response = await this.checkResult(file['name'].split('.')[0] + '.json');
                if (response) {
                    break
                }
                await sleep(1000);
            }
            catch (err) {
                console.log(err);
            }
        }

        // Get number of processed items and consumed capacity from dynamo
        try {
            const counters = await this.getCounters()
            this.setState({
                counterMessage: [{ key: 0, message: 'Items processed: ' + counters.itemsProcessed }, { key: 1, message: 'Consumed capacity: ' + counters.consumedCapacity }]
            })
        }
        catch (err) {
            console.log(err)
        }

        // Turn off loader and messages
        this.setState({
            loading: false,
            statusMessage: 'DONE!',
        })
    }

    handleChangeManufacturer = (e) => {
        //console.log(e.target.value)
        this.setState({
            selectedManufacturer: e.target.value
        })
    }
    handleChangeLanguage = (e) => {
        //console.log(e.target.value)
        this.setState({
            selectedLanguage: e.target.value
        })
    }

    componentDidMount = () => {

    }

    render() {
        const { progress, showAlertSuccess, showAlertError, message, loading, statusMessage, downloadUrls } = this.state;

        return (
            <React.Fragment>
                <hr />
                <form>
                    <div className="form-group mb-3">
                        <label htmlFor="bmecatFile">BMECAT file</label>
                        <input type="file" accept=".xml" className="form-control" id="bmecatFile" onChange={this.handleChange} />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="manufacturer">Manufacturer: <span className="badge badge-info">{this.state.selectedManufacturer}</span></label>
                        <select className="form-control" id="manufacturer" onChange={this.handleChangeManufacturer}>
                            <option value="">Select manufacturer</option>
                            {this.props.manufacturers
                                .filter(manufacturer => 'code' in manufacturer && 'name' in manufacturer)
                                .map(manufacturer => {
                                    return (<option key={manufacturer.id} value={manufacturer.code}>{manufacturer.name}</option>)
                                })}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="language">Language: <span className="badge badge-info">{this.state.selectedLanguage}</span></label>
                        <select className="form-control" id="language" onChange={this.handleChangeLanguage}>
                            <option key="" value="">Select language</option>
                            {this.state.dataLanguage
                                .filter(language => 'English' in language && 'alpha3-b' in language)
                                .map(language => {
                                    return (<option key={language['alpha3-b']} value={language['alpha3-b']}>{language['English']}</option>)
                                })}
                        </select>
                    </div>
                    <button className="btn btn-primary mb-2" onClick={this.onSubmit}>Upload</button>
                </form>                
                <br />
                <br />
                {progress !== 0 && progress !== 100 ? (
                    <div className="progress" style={{ height: "2px" }}>
                        <div className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} style={{ width: progress.toString() + "%" }}></div>
                    </div>
                ) : ""}
                {showAlertSuccess && (
                    <div className="alert alert-info fade show">
                        {message}
                    </div>
                )}
                {showAlertError && (
                    <div className="alert alert-danger fade show">
                        {message}
                    </div>
                )}
                {statusMessage}
                <br />
                {downloadUrls && downloadUrls.map((downloadUrl) => (<span><a href={downloadUrl.url}>{downloadUrl.text}</a><br /></span>))}
                {this.state.counterMessage && this.state.counterMessage.map(message => <span>{message.message}<br /></span>)}
                {loading && (
                    <div>
                        <div className="spinner-border text-primary mt-3 ml-3" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default BmecatForm