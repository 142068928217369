import React, { useState } from 'react'
import axios from 'axios'
import { Fragment } from 'react/cjs/react.production.min'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import ReactJson from 'react-json-view'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import ProgressBar from 'react-bootstrap/ProgressBar'
import ToggleButton from 'react-bootstrap/ToggleButton'

const API = 'https://gw5dj1hws2.execute-api.eu-central-1.amazonaws.com'
const assetsBaseUrl = 'https://07k16kgozh.execute-api.eu-central-1.amazonaws.com'

const bmecatCodes = [
    {
        code: "MD03", name: "Safety data sheet"
    },
    {
        code: "MD04", name: "Deeplink product page"
    },
    {
        code: "MD05", name: "Declaration REACH"
    },
    {
        code: "MD06", name: "Energy label"
    },
    {
        code: "MD07", name: "Product data sheet for energy label"
    },
    {
        code: "MD08", name: "Calibration certificate"
    },
    {
        code: "MD09", name: "Certificate"
    },
    {
        code: "MD10", name: "Circuit diagram"
    },
    {
        code: "MD13", name: "Environment label"
    },
    {
        code: "MD14", name: "Instructions for use"
    },
    {
        code: "MD15", name: "Light cone diagram"
    },
    {
        code: "MD21", name: "Mounting instruction"
    },
    {
        code: "MD22", name: "Product data sheet"
    },
    {
        code: "MD31", name: "Seal of approval"
    },
    {
        code: "MD32", name: "Technical information"
    },
    {
        code: "MD34", name: "Wiring diagram"
    },
    {
        code: "MD38", name: "Management, operation and maintenance document"
    },
    {
        code: "MD41", name: "Sales brochure"
    },
    {
        code: "MD42", name: "AVCP certificate (Assessment and Verification of Constancy of Performance)"
    },
    {
        code: "MD43", name: "CLP (Classification, Labelling and Packaging)"
    },
    {
        code: "MD44", name: "ECOP (Environmental Code of Practice)"
    },
    {
        code: "MD49", name: "Declaration RoHS"
    },
    {
        code: "MD50", name: "Declaration CoC (Certificate of Conformity, requested for CPR)"
    },
    {
        code: "MD51", name: "Declaration DOP (Declaration of performance)"
    },
    {
        code: "MD52", name: "Declaration DOC CE (Declaration of conformity CE)"
    },
    {
        code: "MD53", name: "Declaration BREEAM (Building Research Establishment Environmental Assess-ment Method)"
    },
    {
        code: "MD54", name: "Declaration EPD (Environmental Product Declaration)"
    },
    {
        code: "MD55", name: "Declaration ETA (European Technical Assessment)"
    },
    {
        code: "MD56", name: "Declaration warranty (Warranty statement)"
    },
    {
        code: "MD99", name: "Others"
    }
]


const AssetsCreateAll = (props) => {
    const { handleLoading, handleAlert } = props
    const [token, setToken] = useState('')
    const [files, setFiles] = useState([])
    const [entityId, setEntityId] = useState('')
    const [bmecatCode, setBmecatCode] = useState(null)
    const [progress, setProgress] = useState(0)
    const [uploadResponse, setUploadResponse] = useState(null)
    const [progressBarVisible, setProgressBarVisible] = useState(false)
    const [_private, setPrivate] = useState(false)
    const [overwrite, setOverwrite] = useState(false)
    const [privateLink, setPrivateLink] = useState('')

    const meta = {
        source: 'adm.aleta.hr',
        user: localStorage.getItem('loggedInUser')
    }

    axios.interceptors.response.use(response => {
        return response
    }, async (error) => {
        if (error?.response?.status === 401) {
            console.log('Refreshing token')
            let token = await refreshToken()
            let config = error.config
            config.headers.Authorization = 'Bearer ' + token
            console.log('Returning new token')
            return axios(config)
        }
        return Promise.reject(error)
    })

    const refreshToken = () => new Promise(async (resolve, reject) => {
        let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
            }
        }).catch(err => {
            console.log(err)
            return reject()
        })

        if (!token) return reject('failed')
        setToken(token.data.access_token)
        return resolve(token.data.access_token)
    })

    const getSignedUrl = (bucket, fileName, fileType, action) => new Promise(async (resolve, reject) => {
        const { data: { data: signedUrl } } = await axios
            .post("https://30bevcw8tk.execute-api.eu-central-1.amazonaws.com/getSignedUrl", {
                bucket: bucket,
                file: fileName,
                contentType: fileType,
                action: action
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .catch(err => {
                console.log(err)
                return { data: {} }
            })

        if (!signedUrl) return reject('Error getting signed url')
        return resolve(signedUrl)
    })

    const uploadFile = (signedUrl, file) => {
        var options = {
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted)
            },
            headers: {
                'Content-Type': file['type']
            }
        }

        return axios.put(signedUrl, file, options)
    }

    const onFileChange = async (e) => {
        console.log(e.target.files[0])
        setFiles(e.target.files[0])
    }

    const onSubmit = () => new Promise(async (resolve, reject) => {
        handleLoading(true)
        // if (!entityId) {
        //     handleAlert(false, 'You need to provide entityId')
        //     handleLoading(false)
        //     return resolve(true)
        // }
        setProgressBarVisible(true)
        console.log(entityId)
        const [assetType] = ['document']
        const options = {
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted)
            },
            headers: {
                Authorization: 'Bearer ' + token
            }
        }

        const signedUrl = await getSignedUrl('assets-document-raw', `${files.name}`, files.type, 'putObject')
        console.log('signedUrl', signedUrl)

        await uploadFile(signedUrl, files)

        const { data: resp } = await axios.post(assetsBaseUrl + '/createAsset', { assetType, bmecatCode, private: _private, meta, ...(entityId ? { entityId } : {}), sourcePath: `assets-document-raw/${files.name}`, overwrite }, options).catch(err => {
            console.log(err)
            handleAlert(false, err.response.data.message || 'Error uploading and creating asset')
            return {}
        })

        if (!resp) {
            handleLoading(false)
            return resolve('Error')
        }

        if (_private && resp?.data?.assetPath) {
            const { data: { data: link } } = await axios.post(assetsBaseUrl + '/sign', { url: 'https://assets.aleta.hr/' + resp.data.assetPath.split('/').slice(1).join('/'), validMinutes: 10 }, options).catch(err => {
                console.log(err)
                handleAlert(false, err.response.data.message || 'Error uploading and creating asset')
                return {}
            })

            if (link) {
                setPrivateLink(link)
            }
        }

        handleAlert(true, 'Successfuly uploaded asset')

        setUploadResponse(resp)
        handleLoading(false)
        setTimeout(() => {
            setProgressBarVisible(false)
        }, 10000);

        return resolve(true)
    })

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                return resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                return reject(error)
            }
        })
    }


    React.useEffect(() => {
        const getAccessToken = () => new Promise(async (resolve, reject) => {
            let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
                }
            }).catch(err => {
                console.log(err)
                return reject('failed')
            })

            if (!token) return reject('failed')
            setToken(token.data.access_token)
            resolve(token.data.access_token)
        })

        getAccessToken()
    }, [])


    return (
        <>
            <Row className="justify-content-md-center mt-2">
                <Col xs={12} md={8}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Label>Any document file.</Form.Label>
                        <Form.Control type="file" multiple onChange={onFileChange} />
                    </Form.Group>
                    <Form.Select aria-label="Default select example" onChange={(e) => setBmecatCode(e.target.value || null)}>
                        <option>Select bmecatCode</option>
                        {bmecatCodes.map(x => (
                            <option key={x.code} value={x.code}>
                                {x.code} - {x.name}
                            </option>
                        ))}
                    </Form.Select>
                    <br />
                    <FloatingLabel
                        label="Entity ID"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="entityId"
                            placeholder='Entity ID'
                            onChange={(e) => setEntityId(e.target.value)}
                        />
                        <Form.Text id="passwordHelpBlock" muted>
                            Product No, Manufacturer ID, Category ID, ProductSeries ID, Shop ID <br />
                            1016132, EATON, CAT000003, PS000001, SH000004
                        </Form.Text>
                    </FloatingLabel>
                    <ToggleButton
                        id="private"
                        type="checkbox"
                        variant="outline-warning"
                        checked={_private}
                        onChange={(e) => setPrivate(e.target.checked)}
                    >
                        Private
                    </ToggleButton>
                    <br /><br />
                    <Button variant="primary" onClick={onSubmit}>
                        Upload
                    </Button>
                    {
                        progressBarVisible && (
                            <>
                                <br /><br />
                                <ProgressBar now={progress} label={`${progress}%`} />
                            </>
                        )
                    }
                    {
                        uploadResponse && (
                            <>
                                <br /><br />
                                <strong>Upload response:</strong> <br />
                                <strong>Success:</strong> {uploadResponse.success.toString()} <br />
                                <strong>Data:</strong> {JSON.stringify(uploadResponse.data)}
                            </>
                        )
                    }
                    {
                        privateLink && (
                            <>
                                <br /><br />
                                <strong>Signed URL (valid for 10 minutes):</strong> <br />
                                <a href={privateLink}>{privateLink}</a>
                            </>
                        )
                    }
                </Col>
            </Row >
        </>
    )

}

export default AssetsCreateAll