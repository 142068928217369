import React, { useState } from 'react'
import axios from 'axios'
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ReactJson from 'react-json-view'
import { Fragment } from 'react/cjs/react.production.min'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'

const API = 'https://v32l5ih86b.execute-api.eu-central-1.amazonaws.com/getDiscount'

const DiscountsApi = (props) => {
    const [customerNo, setCustomerNo] = useState('K-00005')
    const [body, setBody] = useState([{ "id": "1016132", "qty": 12 }, { "id": "1764977", "qty": 2 }, { "id": "1659402", "qty": 101 }, { "id": "1014012", "qty": 12 }])
    const [token, setToken] = useState('')
    const [results, setResults] = useState(null)

    const { handleAlert, handleLoading } = props

    const refreshToken = () => new Promise(async (resolve, reject) => {
        let token = await axios.post('https://aleta1.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=359bufj2vc0u7tjfmjel3fqri3', {}, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic MzU5YnVmajJ2YzB1N3RqZm1qZWwzZnFyaTM6MXNhOXM2OXBvbzFqaGgwb2xhNGxkdTlqMzdiMG5vYnZnbTlwZ242NDUwaGE2YmhyaHR2Yg=='
            }
        }).catch(err => {
            console.log(err)
            return reject()
        })

        if (!token) return reject('failed')
        setToken(token.data.access_token)
        return resolve(token.data.access_token)
    })

    const send = async (token) => {
        if (customerNo.length < 7) {
            handleAlert(false, 'Fill customer number')
            return
        }
        if (!body.length) return
        handleLoading(true)
        setResults(null)

        let results = await getApiResponse(token)
        setResults(results)
        handleLoading(false)
    }

    const getApiResponse = async (token) => {
        let tok = token
        let refresh = false
        console.log(JSON.stringify({ customerNo, products: body }))
        let { data: resp } = await axios.post(API, { customerNo: customerNo, products: body }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).catch(async (err) => {
            console.log(err)
            if (err.response && err.response.status === 401) {
                tok = await refreshToken()
                refresh = true
            }
            if (err.response) return (err.response)
            return {}
        })
        if (!resp) {
            if (refresh) {
                return getApiResponse(tok)
            }
            return null
        }
        console.log(resp)
        setResults(resp)
        return (resp)
    }

    React.useEffect(() => {
        const getAccessToken = () => new Promise(async (resolve, reject) => {
            let token = await axios.post('https://aleta1.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=359bufj2vc0u7tjfmjel3fqri3', {}, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic MzU5YnVmajJ2YzB1N3RqZm1qZWwzZnFyaTM6MXNhOXM2OXBvbzFqaGgwb2xhNGxkdTlqMzdiMG5vYnZnbTlwZ242NDUwaGE2YmhyaHR2Yg=='
                }
            }).catch(err => {
                console.log(err)
                return reject('failed')
            })

            if (!token) return reject('failed')
            setToken(token.data.access_token)
            resolve(token.data.access_token)
        })

        getAccessToken()
        //getCheckProductsStatus()
    }, [])

    return (
        <Fragment>
            <Row className='my-3'>
                <Col xs={12} md={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 4 }}>
                    <FloatingLabel
                        label="Customer No"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            value={customerNo}
                            id="customerNo"
                            placeholder='Customer No'
                            onChange={e => setCustomerNo(e.target.value)}
                            onKeyDown={e => e.key === "Enter" && send(token)}
                            autoFocus
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={{ offset: 4, span: 4 }}>
                    <div className="d-grid">
                        <Button onClick={() => send(token)}>Send</Button>
                    </div>
                </Col>

            </Row>
            <Row className='my-3' style={{ minHeight: '400px' }}>
                <Col xs={12} md={6} lg={4} style={{ minHeight: '500px' }}>
                    <div style={{ fontWeight: 'bold' }}>PREVIEW</div>
                    <ReactJson
                        src={body}
                        collapsed={2}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        displayArrayKey={false}
                        enableClipboard={false}
                    />
                </Col>
                <Col xs={12} md={6} lg={{ offset: 0, span: 4 }} >
                    <div style={{ fontWeight: 'bold' }}>EDITOR</div>
                    <Editor
                        value={body}
                        onChange={e => setBody(e)}
                        mode={'code'}
                        navigationBar={false}
                        statusBar={false}
                        htmlElementProps={{ style: { height: 600 } }}
                    />
                </Col>
                <Col xs={12} md={6} lg={4} >
                    <div style={{ fontWeight: 'bold' }}>API RESPONSE</div>
                    <ReactJson
                        src={results || {}}
                        collapsed={3}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        displayArrayKey={false}
                        enableClipboard={false}
                    />
                </Col>
            </Row>
        </Fragment >
    )
}

export default DiscountsApi