import * as React from 'react';
import TranslateForm from './TranslateForm'
import TranslateResults from './TranslateResults'
import TranslateTableData from './TranslateTableData'

class Translate extends React.Component {

    state = {
        translatedText: ''
    }

    handleTranslate = (text) => {
        this.setState({ translatedText: text });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <div className="col-sm-4">
                        <TranslateForm handleTranslate={this.handleTranslate} />
                    </div>
                    <div className="col-sm-4">
                        <TranslateResults data={this.state.translatedText} />
                    </div>
                </div>
                <hr />
                <div className="row justify-content-center">
                    <div className="col-sm-10">
                        <TranslateTableData />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Translate