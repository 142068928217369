import * as React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import Pagination from 'react-bootstrap/Pagination'

const size = 20

const AssetsPDFs = (props) => {
    const { handleLoading, handleAlert } = props
    const [assets, setAssets] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [totalPages, setTotalPages] = React.useState(0)

    React.useEffect(() => {
        const getAccessToken = () => new Promise(async (resolve, reject) => {
            let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
                }
            }).catch(err => {
                console.log(err)
                return reject('failed')
            })

            if (!token) return reject('failed')
            resolve(token.data.access_token)
        })


        const getAssets = () => new Promise(async (resolve, reject) => {
            handleLoading(true)
            const token = await getAccessToken()

            const { data: assets } = await axios.get('https://07k16kgozh.execute-api.eu-central-1.amazonaws.com/assetSearch?type=document&start=' + page * size, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).catch(async (err) => {
                console.log(err)
                return {}
            })

            handleLoading(false)

            if (!assets) {
                return reject('failed')
            }
            setAssets(assets.data)
            if (assets.hits > 20) {
                setTotalPages(Math.ceil(assets.hits / 20) > 10 ? 10 : Math.ceil(assets.hits / 20))
            }
            return resolve(assets.data)
        })

        getAssets()

    }, [page])

    return (
        <>
            <Row className='justify-content-center'>
                <Col xs={12} md={8}>
                    <Table striped bordered hover size="md" style={{ marginTop: 15 }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Description</th>
                                <th>Manufacturer</th>
                                <th>mpn</th>
                                <th>GTIN</th>
                                <th>Asset ID</th>
                                <th>Bmecat Code</th>
                                <th>Bmecat Code Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {assets && assets.map(x => x.assets.map((y, i) => {
                                return (
                                    <tr
                                        key={i}
                                        onClick={() => window.open('https://assets.aleta.hr/' + y.assetPath.split('/')[1], '_blank', 'noopener,noreferrer')}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <th>{x.id || ''}</th>
                                        <th>{y.title || ''}</th>
                                        <th>{y.manufacturerId || ''}</th>
                                        <th>{y.mpn || ''}</th>
                                        <th>{y.gtin || ''}</th>
                                        <th>{y.assetId || ''}</th>
                                        <th>{y.bmecatCode || ''}</th>
                                        <th>{y.bmecatCodeName?.['en-EN'] || ''}</th>
                                    </tr>
                                )
                            }))
                            }
                        </tbody>
                    </Table>
                    {totalPages > 0 && (
                        <Pagination>
                            {
                                [...Array(totalPages).keys()].map(x => {
                                    return (
                                        <Pagination.Item key={x} active={page === x} onClick={() => setPage(x)}>
                                            {x + 1}
                                        </Pagination.Item>
                                    )
                                })
                            }
                        </Pagination>
                    )}
                </Col>
            </Row>
        </>
    )
}

export default AssetsPDFs