import React, { useState } from 'react'
import axios from 'axios'
import { Fragment } from 'react/cjs/react.production.min'

const Watermark = (props) => {
    const [loading, setLoading] = useState(false)
    const [fileToUpload, setFileToUpload] = useState(null)
    const [watermarks, setWatermarks] = useState([])
    const [selectedWatermark, setSelectedWatermark] = useState('')
    const [token, setToken] = useState('')

    React.useEffect(() => {
        const getAccessToken = () => new Promise(async (resolve, reject) => {
            let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
                }
            }).catch(err => {
                console.log(err)
                return null
            })

            if (!token) return reject('failed')
            setToken(token.data.access_token)
            resolve(token.data.access_token)
        })

        const getWatermarks = async () => {
            let accessToken = await getAccessToken()
            let resp = await axios.get('https://07k16kgozh.execute-api.eu-central-1.amazonaws.com/getWatermarks', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            console.log(resp.data)
            setWatermarks(resp.data.data)
        }

        getWatermarks()
    }, [])

    const getSignedUrl = (bucket, fileName, action) => {
        return new Promise((resolve, reject) => {
            axios.post("https://30bevcw8tk.execute-api.eu-central-1.amazonaws.com/getSignedUrl", {
                bucket: bucket,
                file: fileName,
                action: action
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    const uploadFile = (signedUrl, file) => {
        return new Promise((resolve, reject) => {
            var options = {
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                },
                headers: {
                    'Content-Type': file['type']
                }
            };

            axios.put(signedUrl, file, options)
                .then(response => {
                    setLoading(false)
                    setWatermarks(previous => [...previous, { Key: 'watermarks/' + file.name }])
                    resolve()
                })
                .catch(err => {
                    setLoading(false)
                    reject(err.message)
                })
        })
    }

    const handleFileUploadChange = (e) => {
        setFileToUpload(e.target.files[0])
    }

    const onUpload = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (fileToUpload) {
            let signedUrl = await getSignedUrl('assets-raw', 'watermarks/' + fileToUpload.name, 'putObject')
            await uploadFile(signedUrl, fileToUpload)
        }
    }

    const onChangeAllWatermarks = (e) => {
        e.preventDefault()
        setLoading(true)
        axios.post("https://07k16kgozh.execute-api.eu-central-1.amazonaws.com/watermark", {
            watermark: selectedWatermark
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <div className="row justify-content-center mt-3 pt-3">
                <div className="col-sm-4">
                    <hr />
                    Upload new watermark
                    <hr />
                    <form>
                        <div className="form-group mb-3">
                            <label htmlFor="exampleFormControlFile1">Select watermark</label>
                            <input type="file" className="form-control" id="exampleFormControlFile1" onChange={handleFileUploadChange} />
                        </div>
                    </form>
                    <div className="d-grid gap-2">
                        <button className="btn btn-primary mb-3" onClick={onUpload} disabled={loading} >Upload watermark</button>
                    </div>

                    <hr />
                    Change watermark on all assets
                    <hr />
                    <select className="form-control mb-3" onChange={(e) => setSelectedWatermark(e.target.value)}>
                        <option>Select Watermark</option>
                        {watermarks && watermarks.map(w => {
                            return (<option key={w.Key.split('/')[1]} value={w.Key.split('/')[1]}>{w.Key.split('/')[1]}</option>)
                        })}
                    </select>
                    <div className="d-grid gap-2">
                        <button className="btn btn-secondary mb-3" onClick={onChangeAllWatermarks} disabled={loading}>Change watermark on all assets</button>
                    </div>

                </div>
            </div>
            <div className="row justify-content-center mt-5 pt-5">
                {loading && (
                    <div className="row justify-content-center mb-2">
                        <div className="spinner-border text-primary mt-3 ml-3" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default Watermark