import * as React from 'react';

class Home extends React.Component {

    render() {
        return (
            <React.Fragment>
                <h1>Text za home stranicu</h1>
            </React.Fragment>
        )
    }
}

export default Home