import React, { useRef, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import Pagination from 'react-bootstrap/Pagination'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'


const Signer = (props) => {
    const { handleLoading, handleAlert } = props

    const [firstName, setFirstName] = useState('Mladen')
    const [lastName, setLastName] = useState('Aleta')
    const [documentId, setDocumentId] = useState('OTP23-123123')

    const send = () => {
        if (firstName && lastName && documentId) {
            const width = 600
            const height = 400
            const pos = {
                x: (window.screen.width - width) / 2,
                y: (window.screen.height - height) / 3
            }
            console.log(window.screen.width, window.screen.height)
            const windowFeatures = `left=${pos.x},top=${pos.y},width=${width},height=${height},popup,scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no`
            //window.open(`http://localhost:3000/sign?fname=${firstName}&lname=${lastName}&documentId=${documentId}`, 'signWindow', windowFeatures)
            window.open(`https://signature.aleta.hr?fname=${firstName}&lname=${lastName}&documentId=${documentId}`, 'signWindow', windowFeatures)
            //window.open(`http://signature.aleta.hr.s3-website.eu-central-1.amazonaws.com?fname=${firstName}&lname=${lastName}&documentId=${documentId}`, 'signWindow', windowFeatures)
        }
        else {
            handleAlert('warning', 'You need to provide all data')
        }
    }

    return (
        <>
            <Row className='justify-content-center' style={{ marginTop: 15 }}>
                <Col xs={12} md={4}>
                    <FloatingLabel
                        label="First name"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="fname"
                            placeholder='First name'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Last name"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="lname"
                            placeholder='Last name'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Document Id"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="documetnId"
                            placeholder='Document Id'
                            value={documentId}
                            onChange={(e) => setDocumentId(e.target.value)}
                        />
                    </FloatingLabel>
                    <Button variant="primary" onClick={send}>
                        Sign
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default Signer