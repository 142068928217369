import React, { Fragment } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './Components/PrivateRoute'
import Nav from './Components/Nav'
import Home from './Components/Home'
import Bmecat from './Components/Bmecat'
import Etim from './Components/Etim'
import Translate from './Components/Translate'
import Assets from './Components/Assets'
import FullProduct from './Components/FullProduct'
import Login from './Components/Login'
import Test from './Components/Test'
import GetAssets from './Components/GetAssets'
import Watermark from './Components/Watermark'
import Metel from './Components/Metel'
import Sync from './Components/Sync'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import CheckProduct from './Components/CheckProduct'
import DiscountsApi from './Components/DiscountsApi'
import AssetsPDFs from './Components/AssetsPdfs';
import AssetsCreatePdf from './Components/AssetsCreatePdf';
import Assets360 from './Components/Assets360';
import AssetsCreate360 from './Components/AssetsCreate360';
import AssetsCreateImage from './Components/AssetsCreateImage';
import AssetsImages from './Components/AssetsImages';
import ParseDescription from './Components/ParseDescription';
import ParseDescription2 from './Components/ParseDescription2';
import Signer from './Components/Signer';
import Sign from './Components/Sign';
import AssetsCreateAll from './Components/AssetsCreateAll';


const urlGetNavManufacturers = 'https://zuoo4098q7.execute-api.eu-central-1.amazonaws.com/'
const statusUrl = 'https://36oz17shqk.execute-api.eu-central-1.amazonaws.com/status'
const API = 'https://gw5dj1hws2.execute-api.eu-central-1.amazonaws.com'

const bmecatFilesAPI = 'https://g3re91yr6h.execute-api.eu-central-1.amazonaws.com/bmecat/check'

class App extends React.Component {
    state = {
        loading: false,
        logout: false,
        logiran: false,
        etimData: { loading: false, message: '', data: [] },
        bmecatFiles: [],
        dataManufacturers: [],
        alert: null,
        token: null,
        populateStatus: {
            message: '',
            data: {}
        },
        checkProductsStatus: null,
    }

    componentDidMount = () => {
        const logiran = !!localStorage.getItem('loggedIn') && localStorage.getItem('expires') > Date.now()
        this.setState({ logiran })
        if (logiran) {
            this.getBmecatFileList()
            this.getManufacturers()

            this.refreshToken()
                .then(tok => {
                    this.setState({ token: tok })
                })
                .catch(err => {
                    console.log(err)
                })
            this.getPopulateStatus(true)
            this.getCheckProductsStatus(true)
        }
    }

    refreshToken = () => new Promise(async (resolve, reject) => {
        let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
            }
        }).catch(err => {
            console.log(err)
            return reject()
        })

        if (!token) return reject('failed')
        this.setState({ token: token.data.access_token })
        return resolve(token.data.access_token)
    })

    handleLoading = (loading) => {
        this.setState({ loading })
    }

    handleLogout = () => {
        localStorage.removeItem('loggedIn')
        localStorage.removeItem('expires')
        this.setState({ logout: true, logiran: false })
    }

    handleLogin = () => {
        this.setState({ logiran: !!localStorage.getItem('loggedIn') && localStorage.getItem('expires') > Date.now() })
        this.getPopulateStatus(true)
        this.getCheckProductsStatus(true)
    }

    handleAlert = (success, message) => {
        this.setState({ alert: { success, message } })
    }

    getBmecatFileList = async () => {
        try {
            const files = await axios.get(bmecatFilesAPI)
            if (files.data.length > 0) {
                this.setState({
                    bmecatFiles: files.data
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    getManufacturers = () => {
        axios.get(urlGetNavManufacturers, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                this.setState({
                    dataManufacturers: response.data
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    handleGetEtimStatus = async () => {
        const sleep = ms => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }
        this.setState({
            etimData: {
                loading: true,
                message: 'Waiting for etim report...',
                data: []
            }
        })
        let attempts = 90
        for (let i = 0; i < attempts; i++) {
            try {
                const resp = await axios.get(statusUrl)
                this.setState({
                    etimData: {
                        loading: false,
                        message: 'DONE',
                        data: resp.data.messages
                    }
                })
                return
            }
            catch (err) {
                //console.log(err)
            }
            if (i === attempts - 1) {
                console.log('Unsuccessful with getting status in: ' + attempts + ' attempts')
                this.setState({
                    etimData: {
                        loading: false,
                        message: 'Unsuccessful with getting status in: ' + attempts + ' attempts',
                        data: []
                    }
                })
                return
            }
            await sleep(10000)
        }
    }

    getPopulateStatusMessage = async (token) => {
        let tok = token
        let refresh = false
        const resp = await axios.get(API + '/getPopulateStatus',
            {
                headers: {
                    Authorization: `Bearer ${tok}`
                }
            }
        ).catch(async (err) => {
            console.log(err)
            if (err.response && err.response.status === 401) {
                tok = await this.refreshToken()
                refresh = true
            }
            return null
        })
        if (!resp) {
            if (refresh) {
                return this.getPopulateStatusMessage(tok)
            }
            return ('Error getting status')
        }
        return resp.data
    }

    getCheckProductStatusMessage = async () => {
        let tok = this.state.token
        let refresh = false
        const resp = await axios.get(API + '/getCheckProductStatus',
            {
                headers: {
                    Authorization: `Bearer ${tok}`
                }
            }
        ).catch(async (err) => {
            if (err.response && err.response.status === 401) {
                tok = await this.refreshToken()
                refresh = true
            }
            else { console.log(err) }
            return null
        })
        if (!resp) {
            if (refresh) {
                return this.getCheckProductStatusMessage()
            }
            return ('Error getting status')
        }
        return resp.data
    }

    getPopulateStatus = (initialCall) => new Promise(async (resolve, reject) => {
        const sleep = ms => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }
        let work = true
        this.setState({
            populateStatus: {
                message: '',
                data: {}
            }
        })
        do {
            const resp = await this.getPopulateStatusMessage(this.state.token).catch(err => {
                console.log(err)
                return null
            })
            if (!resp) {
                this.handleAlert(false, 'Error getting status message')
                console.log('Error getting status message')
                return
            }
            //console.log(resp.data)
            //let data = JSON.parse(resp.data)
            if (!resp?.data?.message) {
                if (initialCall) {
                    console.log('Initial call. No data. Return')
                    return
                }
            }
            else {
                initialCall = false
                let data = JSON.parse(resp.data.message)
                this.setState({
                    populateStatus: {
                        message: resp.data.status,
                        data
                    }
                })
                if (resp.data.status === 'finished') return
            }

            await sleep(3000)
        }
        while (work)
    })

    getCheckProductsStatus = (initialCall) => new Promise(async (resolve, reject) => {
        const sleep = ms => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }
        let work = true
        this.setState({
            checkProductsStatus: null
        })
        do {
            const resp = await this.getCheckProductStatusMessage().catch(err => {
                console.log(err)
                return null
            })
            if (!resp) {
                this.handleAlert(false, 'Error getting status message')
                console.log('Error getting status message')
                return
            }
            let { data } = resp
            //let data = JSON.parse(resp.data)
            if (initialCall && (!data || data.status !== 'working')) {
                console.log('Initial call. No data. Return')
                return
            }
            initialCall = false
            this.setState({
                checkProductsStatus: data
            })
            if (data.status === '0') return

            await sleep(3000)
        }
        while (work)
    })

    render() {
        return (
            <Fragment>
                <BrowserRouter>
                    {this.state.logout === true && (<Redirect to='/' />)}
                    <Nav handleLogout={this.handleLogout} loggedIn={this.state.logiran} />
                    <div className="container-fluid">
                        <div style={{ display: this.state.loading ? 'flex' : 'none', position: 'fixed', justifyContent: 'center', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,0.4)', transform: 'none !important', overflow: 'hidden', zIndex: 99999 }}>
                            <Spinner animation='border' variant='primary' style={{ position: 'absolute', top: '50%' }} />
                        </div>
                        <Switch>
                            <PrivateRoute exact={true} path="/" component={Home} handleLogout={this.handleLogout} />
                            <Route exact={true} path="/login" render={(props) => (<Login {...props} handleLogin={this.handleLogin} handleLoading={this.handleLoading} handleAlert={this.handleAlert} />)} />
                            <PrivateRoute exact={true} path="/etim" component={Etim} handleLogout={this.handleLogout} handleGetEtimStatus={this.handleGetEtimStatus} data={this.state.etimData} />
                            <PrivateRoute exact={true} path="/bmecat" component={Bmecat} handleLogout={this.handleLogout} manufacturers={this.state.dataManufacturers} />
                            <PrivateRoute exact={true} path="/translate" component={Translate} handleLogout={this.handleLogout} />
                            <PrivateRoute exact={true} path="/assets" component={Assets} handleLogout={this.handleLogout} bmecatFiles={this.state.bmecatFiles} manufacturers={this.state.dataManufacturers} />
                            <PrivateRoute exact={true} path="/fullproduct" component={FullProduct} handleLogout={this.handleLogout} />
                            <PrivateRoute exact={true} path="/test" component={Test} handleLogout={this.handleLogout} />
                            <PrivateRoute exact={true} path="/get-assets" component={GetAssets} handleLogout={this.handleLogout} />
                            <PrivateRoute exact={true} path="/watermark" component={Watermark} handleLogout={this.handleLogout} />
                            <PrivateRoute exact={true} path="/metel" component={Metel} handleLogout={this.handleLogout} handleLoading={this.handleLoading} />
                            <PrivateRoute exact={true} path="/sync" component={Sync} handleLogout={this.handleLogout} handleLoading={this.handleLoading} getPopulateStatus={this.getPopulateStatus} populateStatus={this.state.populateStatus} handleAlert={this.handleAlert} />
                            <PrivateRoute exact={true} path="/checkProduct" component={CheckProduct} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} getCheckProductsStatus={this.getCheckProductsStatus} checkProductsStatus={this.state.checkProductsStatus} />
                            <PrivateRoute exact={true} path="/discountApi" component={DiscountsApi} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} />
                            <PrivateRoute exact={true} path="/assets-360" component={Assets360} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} token={this.token} />
                            <PrivateRoute exact={true} path="/assets-pdfs" component={AssetsPDFs} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} token={this.token} />
                            <PrivateRoute exact={true} path="/assets-images" component={AssetsImages} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} token={this.token} />
                            <PrivateRoute exact={true} path="/assets-create-360" component={AssetsCreate360} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} />
                            <PrivateRoute exact={true} path="/assets-create-pdf" component={AssetsCreatePdf} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} />
                            <PrivateRoute exact={true} path="/assets-create-all" component={AssetsCreateAll} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} />
                            <PrivateRoute exact={true} path="/assets-create-image" component={AssetsCreateImage} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} />
                            <PrivateRoute exact={true} path="/parse-description" component={ParseDescription} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} />
                            <PrivateRoute exact={true} path="/parse-description2" component={ParseDescription2} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} />
                            <PrivateRoute exact={true} path="/signer" component={Signer} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} />
                            <PrivateRoute exact={true} path="/sign" component={Sign} handleLogout={this.handleLogout} handleLoading={this.handleLoading} handleAlert={this.handleAlert} />
                        </Switch>
                        {this.state.alert && (
                            <ToastContainer className="p-3 position-static">
                                <Toast onClose={() => this.setState({ alert: null })} animation={false} show={this.state.alert} delay={10000} autohide>
                                    <Toast.Header className={this.state.alert.success ? 'bg-info text-dark' : 'bg-warning text-dark'}>
                                        <strong className="me-auto">Message</strong>
                                    </Toast.Header>
                                    <Toast.Body>{this.state.alert.message}</Toast.Body>
                                </Toast>
                            </ToastContainer>
                        )}
                    </div>
                </BrowserRouter>

            </Fragment>
        )
    }
}

export default App;