import React, { useRef } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import Pagination from 'react-bootstrap/Pagination'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import { useLocation } from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner'

const boxStyle = {
    height: "70mm",
    width: "120mm",
    border: "1px solid #d3d3d3",
};



const Sign = (props) => {
    const { handleLoading, handleAlert } = props

    const [started, setStarted] = React.useState(false)

    const imageBox = useRef();
    const txtSignature = useRef();
    const txtDisplay = useRef();
    const chkB64 = useRef();
    const chkSigText = useRef();
    const btnRestore = useRef()

    const loc = useLocation()

    const params = loc.search.replace('?', '').split('&')

    const { fname, lname, documentId } = params.reduce((acc, cur) => {
        const data = cur.split('=')
        acc[data[0]] = data[1]
        return acc
    }, {})

    React.useEffect(() => {
        const initiateSubmit = ({ detail: data }) => {
            console.log('image ready event received', data.image)
            submit({ image: data.image, documentId })
        }

        if (fname && lname && documentId && !started) {
            setStarted(true)
            window.bodyOnLoad(document.getElementById("txtDisplay"), document.getElementById("chkShowSigText"), document.getElementById("Restore"))
            document.getElementById('imageBox').addEventListener('imageReady', initiateSubmit)
            setTimeout(() => {
                console.log('call capture');
                window.capture(fname, lname, documentId, imageBox.current, txtSignature.current, chkSigText.current, chkB64.current)
            }, 1000)
        }
        return () => {
            document.getElementById("imageBox").removeEventListener('imageReady', initiateSubmit)
        }
    }, [fname, lname, documentId])

    // React.useEffect(() => {


    //     if (document.getElementById('imageBox')) {
    //         document.getElementById('imageBox').addEventListener('imageReady', initiateSubmit)
    //     }

    //     return () => {
    //         document.getElementById("imageBox").removeEventListener('imageReady', initiateSubmit)
    //     }
    // }, [])

    if (!fname || !lname || !documentId) {
        return (
            <>
                <Row className='justify-content-center' style={{ marginTop: 15 }}>
                    Error getting params
                </Row>
            </>
        )
    }

    const getAccessToken = () => new Promise(async (resolve, reject) => {
        let token = await axios.post('https://aleta1.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=359bufj2vc0u7tjfmjel3fqri3', {}, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic MzU5YnVmajJ2YzB1N3RqZm1qZWwzZnFyaTM6MXNhOXM2OXBvbzFqaGgwb2xhNGxkdTlqMzdiMG5vYnZnbTlwZ242NDUwaGE2YmhyaHR2Yg=='
            }
        }).catch(err => {
            console.log(err)
            return reject('failed')
        })

        if (!token) return reject('failed')
        return resolve(token.data.access_token)
    })

    const submit = async ({ image, documentId }) => new Promise(async (resolve, reject) => {
        const token = await getAccessToken().catch(err => {
            console.log(err)
            return null
        })
        if (!token) {
            return reject('No token')
        }

        let { data: resp } = await axios.post('https://cognis.aleta.hr/signature', { image, documentId }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).catch(async (err) => {
            console.log(err)
            return {}
        })
        if (!resp) {
            return reject('Error with cognis request')
        }
        console.log(resp)
        let newWindow = window.open("about:blank", "_self")
        newWindow.close()
        return resolve(true)
    })

    return (
        <>
            <div style={{ display: 'flex', position: 'fixed', justifyContent: 'center', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,0.83)', transform: 'none !important', overflow: 'hidden', zIndex: 99999 }}>
                <div style={{ position: 'absolute', top: '50%', textAlign: 'center', fontSize: '2em', color: 'grey' }}>
                    <Spinner animation='border' variant='primary' as={'span'} /><br />
                    Waiting for signature
                </div>
            </div>
            <Row className='justify-content-center' style={{ marginTop: 15 }}>
                <Col xs={12} md={4}>
                    First name: {fname}
                    <br />
                    Last name: {lname}
                    <br />
                    Document: {documentId}
                    <br />
                    <FloatingLabel
                        label="First name"
                        className="mb-3"
                        hidden
                    >
                        <Form.Control
                            type="text"
                            id="fname"
                            placeholder='First name'
                            defaultValue={fname}
                            disabled
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Last name"
                        className="mb-3"
                        hidden
                    >
                        <Form.Control
                            type="text"
                            id="lname"
                            placeholder='Last name'
                            defaultValue={lname}
                            disabled
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Document Id"
                        className="mb-3"
                        hidden
                    >
                        <Form.Control
                            type="text"
                            id="documetnId"
                            placeholder='Document Id'
                            defaultValue={documentId}
                            disabled
                        />
                    </FloatingLabel>
                    <Form.Control
                        as="textarea"
                        rows={10}
                        id="txtDisplay"
                        placeholder='Log output'
                        defaultValue=""
                        readOnly
                        ref={txtDisplay}
                    />
                    <div id="imageBox" ref={imageBox} style={boxStyle} onChange={submit}></div>
                    <div style={{ display: 'none' }}>
                        <br />
                        <input type="checkbox" id="chkUseB64Image" ref={chkB64} defaultChecked={true} />Use base-64 signature image
                        <br />
                        <input type="checkbox" id="chkShowSigText" ref={chkSigText} />Output SigText to form
                        <br /><br />
                        <hr style={{ marginTop: 15 }} />

                        <br />SigText:<br />
                        <textarea cols="100" rows="15" id="txtSignature" ref={txtSignature}></textarea>
                        <button value="Restore" id="Restore" ref={btnRestore}>Restore</button>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Sign