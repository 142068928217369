import React, { useState } from 'react'
import axios from 'axios'
import { Fragment } from 'react/cjs/react.production.min'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'

const API = 'https://gw5dj1hws2.execute-api.eu-central-1.amazonaws.com'

const CheckProduct = (props) => {
    const [id, setId] = useState('')
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState('')
    const [results, setResults] = useState(null)
    const [checkProductsLoading, setCheckProductsLoading] = useState(false)

    const { getCheckProductsStatus, checkProductsStatus, handleAlert, handleLoading } = props

    const eanDiff = () => {
        let eans = []
        Object.keys(results).map(source => {
            if (results[source].ean) eans.push(results[source].ean)
            return null
        })
        return (new Set(eans)).size > 1
    }

    const mpnDiff = () => {
        let mpns = []
        Object.keys(results).map(source => {
            if (results[source].mpn) mpns.push(results[source].mpn)
            return null
        })
        return (new Set(mpns)).size > 1
    }

    const refreshToken = () => new Promise(async (resolve, reject) => {
        let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
            }
        }).catch(err => {
            console.log(err)
            return reject()
        })

        if (!token) return reject('failed')
        setToken(token.data.access_token)
        return resolve(token.data.access_token)
    })

    const search = async (token) => {
        if (id.length !== 7) { return }
        handleLoading(true)
        setResults(null)

        let results = await getDynamoProduct(token)
        setResults(results)
        handleLoading(false)
    }

    const getDynamoProduct = async (token) => {
        let tok = token
        let refresh = false
        let resp = await axios(API + '/checkProduct/' + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).catch(async (err) => {
            console.log(err)
            if (err.response && err.response.status === 401) {
                tok = await refreshToken()
                refresh = true
            }
            return null
        })
        if (!resp) {
            if (refresh) {
                return getDynamoProduct(tok)
            }
            return null
        }
        return (resp.data.data)
    }

    const checkAllProducts = async (token) => {
        handleLoading(true)
        setLoading(true)
        let tok = token
        let refresh = false
        let resp = await axios.post(API + '/checkProduct',
            { start: true },
            {
                headers: {
                    Authorization: `Bearer ${tok}`
                }
            })
            .catch(async (err) => {
                console.log(err)
                if (err.response && err.response.status === 401) {
                    tok = await refreshToken()
                    refresh = true
                }
                return null
            })
        if (!resp) {
            if (refresh) {
                return checkAllProducts(tok)
            }
            handleAlert(false, 'Error checking products')
        }
        else {
            getCheckProductsStatus()
        }
        handleLoading(false)
        return
    }

    React.useEffect(() => {
        const getAccessToken = () => new Promise(async (resolve, reject) => {
            let token = await axios.post('https://aleta-secure-api.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=6lj9cv9dgkq3hoo94fhec5scgh', {}, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic NmxqOWN2OWRna3EzaG9vOTRmaGVjNXNjZ2g6MTdxOHU3OGhsNTd1cTc1aTRxdjU4ZmZkbHFlYjlncW5ucTBiczJrNmZrdGk3MDNrMWhycw=='
                }
            }).catch(err => {
                console.log(err)
                return reject('failed')
            })

            if (!token) return reject('failed')
            setToken(token.data.access_token)
            resolve(token.data.access_token)
        })

        getAccessToken()
        //getCheckProductsStatus()
    }, [])

    React.useEffect(() => {
        if (checkProductsStatus) {
            setCheckProductsLoading(checkProductsStatus.status === 'working')
            setLoading(checkProductsStatus.status === 'working')
        }
    }, [checkProductsStatus])

    return (
        <Fragment>
            <Row className='justify-content-center my-3'>
                <Col xs={12} md={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 4 }}>
                    <FloatingLabel
                        label="bc product ID"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            id="search"
                            placeholder='bc product ID'
                            onChange={e => setId(e.target.value)}
                            onKeyDown={e => e.key === "Enter" && search(token)}
                            autoFocus
                        />
                    </FloatingLabel>
                    <div className="d-grid">
                        <Button onClick={() => search(token)}>Check</Button>
                    </div>
                    <div className="d-grid my-4">
                        <Button variant='link' onClick={() => checkAllProducts(token)} disabled={loading || checkProductsLoading}>Check ALL products and generate report</Button>
                    </div>
                    {checkProductsLoading && (
                        <>
                            <Spinner animation="grow" variant="danger" />
                            <p style={{ marginTop: 20 }}>Provjeravam proizvode. Saljem mail kada zavrsim.<br />
                                {checkProductsStatus && <span style={{ fontWeight: 'bold' }}>Processed: {checkProductsStatus.counters.processed}<br />Written: {checkProductsStatus.counters.written}</span>}
                            </p>
                            <hr />
                        </>
                    )}
                </Col>
                <Col xs={12} md={3} lg={4}>
                    {results && (results.productTable?.image ? (<img src={'https://img.aleta.hr/200x200/' + results.productTable.image} alt='' />) : (<h2 style={{ fontWeight: 'bold' }}>No image</h2>))}
                </Col>
            </Row>
            {results ? (
                <>
                    <Row xs={1} md={2} lg={3} className='justify-content-center'>
                        <Col>
                            <p><span style={{ fontWeight: 'bold' }}>Group: </span>{results?.productTable?.group}</p>
                            <p><span style={{ fontWeight: 'bold' }}>Class: </span>{results?.productTable?.class}</p>
                            {results.productTable?.meta && (<p><span style={{ fontWeight: 'bold' }}>Meta (dynamo product table): </span>
                                {results.productTable.meta.source} | {new Date(results.productTable.meta.updated).toLocaleString('en-GB')}
                            </p>)}
                            {results.productTable?.metelMerge && (<p><span style={{ fontWeight: 'bold' }}>Metel merge: </span>
                                {results.productTable.metelMerge.user} | {new Date(results.productTable.metelMerge.updated).toLocaleString('en-GB')}, 
                                Metel data mutation date: {new Date(results.productTable.metelMerge.metelDataMutationDate).toLocaleString('en-GB')}
                            </p>)}
                        </Col>
                    </Row>
                    <div className="row justify-content-center mt-2 pt-2">
                        <div className="col-lg-8">
                            <div className='table-responsive'>
                                <table className="table table-sm table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Source</th>
                                            <th scope="col" style={eanDiff() ? { backgroundColor: 'red' } : {}}>Ean</th>
                                            <th scope="col" style={mpnDiff() ? { backgroundColor: 'red' } : {}}>Mpn</th>
                                            <th scope="col">Manufacturer ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(results).map(key => (
                                            <tr key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{key}</td>
                                                <td >{results[key].ean}</td>
                                                <td>{results[key].mpn}</td>
                                                <td>{results[key].manufacturerId}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </Fragment >
    )
}

export default CheckProduct