import * as React from 'react'
import axios from 'axios'

const assetsUrl = 'https://6w1awxtir0.execute-api.eu-central-1.amazonaws.com/assets'
//const assetsUrl = 'htts://127.0.0.1/assets'

const getAssetType = (bmecatCode) => {
    switch (bmecatCode) {
        case 'MD01':
        case 'MD02':
        case 'MD12':
        case 'MD23':
        case 'MD24':
        case 'MD25':
        case 'MD26':
        case 'MD27':
        case 'MD28':
        case 'MD29':
        case 'MD30':
        case 'MD47':
            return 'image'
        case '':
        case 'MD05':
        case 'MD06':
        case 'MD07':
        case 'MD08':
        case 'MD09':
        case 'MD13':
        case 'MD31':
        case 'MD33':
        case 'MD35':
        case 'MD42':
        case 'MD43':
        case 'MD44':
        case 'MD49':
        case 'MD50':
        case 'MD51':
        case 'MD52':
        case 'MD53':
        case 'MD54':
        case 'MD55':
        case 'MD56':
        case 'MD04':
        case 'MD22':
        case 'MD14':
        case 'MD32':
        default:
            return 'document'
        case 'MD46':
            return 'image360'
    }
}

class Assets extends React.Component {
    state = { loading: false, selectedFile: '', selectedManufacturer: '', assets: [], failed: [], path: '', assetS3: false, progress: 0 }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    onSubmit = async (e) => {
        e.preventDefault()

        if (this.state.selectedManufacturer === '' || this.state.selectedFile === '') { return }

        this.setState({
            assets: [],
            failed: [],
            progress: 0,
            loading: true
        })

        // Get signed URL to download selected JSON file
        let signedUrl
        try {
            signedUrl = await this.getSignedUrl('bmecat-json', this.state.selectedFile, 'getObject')
        }
        catch (err) {
            console.log(err)
            return
        }

        // Get bmecat file from s3 and parse it
        let file
        try {
            file = await this.getFile(signedUrl)
        }
        catch (err) {
            console.log(err)
            this.setState({
                loading: false
            })
            return
        }

        // Check that file is loaded
        if (!file) {
            console.log('No valid file loaded')
            this.setState({
                loading: false
            })
            return
        }

        // Get all assets from file
        try {
            await this.getAssets(file)
            this.setState({
                loading: false
            })
        }
        catch (err) {
            console.log(err)
            this.setState({
                loading: false
            })
            return
        }

        this.setState({
            loading: false
        })
    }

    handleAssetS3Change = (e) => {
        this.setState({ assetS3: e.target.checked })
    }

    handlePathChange = (e) => {
        this.setState({ path: e.target.value })
    }

    handleChangeBMECatFile = (e) => {
        this.setState({ selectedFile: e.target.value })
    }

    handleChangeManufacturer = (e) => {
        this.setState({ selectedManufacturer: e.target.value })
    }

    getAssets = (file) => {
        var path = ''
        if (this.state.path !== '') {
            path = this.state.path
            path += path.endsWith('/') ? '' : '/'
        }
        return new Promise(async (resolve, reject) => {
            var data = []
            var skipped = []
            var promises = []
            var view360 = []
            //var flag = false
            for (let i = 0; i < file.length; i++) {
                // To continue if something went wrong
                // if (file[i]['SUPPLIER_PID'][0] === 'XS108B3PBM12') {
                //     flag = true
                // }
                //if (!flag) { continue }
                let gtin = ''
                //let upc = ''
                if ('INTERNATIONAL_PID' in file[i]['PRODUCT_DETAILS'][0]) {
                    let internationalPid = file[i]['PRODUCT_DETAILS'][0]['INTERNATIONAL_PID']
                    try {
                        gtin = internationalPid.filter(pid => (pid['$']['type'].toLowerCase() === 'gtin' || pid['$']['type'].toLowerCase() === 'ean'))[0]['_']
                    }
                    catch (err) { }
                    try {
                        // upc = internationalPid.filter(pid => pid['$']['type'].toLowerCase() === 'upc')[0]['_']
                    }
                    catch (err) { }
                }
                try {
                    if ('MIME_INFO' in file[i]) {
                        // VERSION 3
                        let mimeInfo = file[i]['MIME_INFO'][0]['MIME']
                        for (let j = 0; j < mimeInfo.length; j++) {
                            let mimeSource = typeof mimeInfo[j]['MIME_SOURCE'][0] === 'string' ? mimeInfo[j]['MIME_SOURCE'][0] : mimeInfo[j]['MIME_SOURCE'][0]['_']
                            if (!mimeSource) { continue }
                            let item = {
                                manufacturer: this.state.selectedManufacturer,
                                mpn: file[i]['SUPPLIER_PID'][0],
                                ean: gtin,
                                bmecatCode: mimeInfo[j]['MIME_DESCR'][0],
                                designation: 'MIME_PURPOSE' in mimeInfo[j] ? mimeInfo[j]['MIME_PURPOSE'][0] : '',
                                assetType: getAssetType(mimeInfo[j]['MIME_DESCR'][0]),
                                sourcePath: mimeSource
                            }

                            //console.log(this.state.selectedManufacturer)
                            //console.log(item.type)

                            if (this.state.selectedManufacturer === 'VIMAR' && item.bmecatCode === 'MD22') {
                                item.sourcePath = `https://www.vimar.com/en/int/catalog/product/download-pdf/code/${encodeURIComponent(file[i]['SUPPLIER_PID'][0])}?type=.pdf`
                            }
                            else if (this.state.selectedManufacturer === 'SCHNEIDER' && mimeInfo[j]['MIME_TYPE'][0] === 'image/jpeg' && !mimeInfo[j]['MIME_SOURCE'][0].includes('http')) {
                                let itemId = mimeInfo[j]['MIME_SOURCE'][0].split('_')
                                itemId.shift()
                                itemId = itemId.join('_').split('.')[0]
                                item.sourcePath = `https://download.schneider-electric.com/files?p_Doc_Ref=${encodeURIComponent(itemId)}&p_File_Type=rendition_1500_jpg`
                            }
                            if (!item.sourcePath.includes('http') && path) {
                                item.sourcePath = (path + item.sourcePath)
                            }
                            if (item.assetType === '360view') {
                                view360.push(item)
                            } else {
                                data.push(item)
                            }
                        }
                    }
                    else if ('UDX.EDXF.MIME_INFO' in file[i]['USER_DEFINED_EXTENSIONS'][0] && 'UDX.EDXF.MIME' in file[i]['USER_DEFINED_EXTENSIONS'][0]['UDX.EDXF.MIME_INFO'][0]) {
                        let mimeInfo = file[i]['USER_DEFINED_EXTENSIONS'][0]['UDX.EDXF.MIME_INFO'][0]['UDX.EDXF.MIME']
                        for (let j = 0; j < mimeInfo.length; j++) {
                            let mimeSource = typeof mimeInfo[j]['UDX.EDXF.MIME_SOURCE'][0] === 'string' ? mimeInfo[j]['UDX.EDXF.MIME_SOURCE'][0] : mimeInfo[j]['UDX.EDXF.MIME_SOURCE'][0]['_']
                            if (!mimeSource) { continue }
                            let item = {
                                manufacturer: this.state.selectedManufacturer,
                                mpn: file[i]['SUPPLIER_PID'][0],
                                ean: gtin,
                                bmecatCode: mimeInfo[j]['UDX.EDXF.MIME_CODE'][0],
                                designation: 'UDX.EDXF.MIME_DESIGNATION' in mimeInfo[j] ? mimeInfo[j]['UDX.EDXF.MIME_DESIGNATION'][0] : '',
                                assetType: getAssetType(mimeInfo[j]['UDX.EDXF.MIME_CODE'][0]),
                                sourcePath: mimeSource
                            }

                            if (this.state.selectedManufacturer === 'VIMAR' && item.bmecatCode === 'MD22') {
                                item.sourcePath = `https://www.vimar.com/en/int/catalog/product/download-pdf/code/${encodeURIComponent(file[i]['SUPPLIER_PID'][0])}?type=.pdf`
                            }
                            else if (this.state.selectedManufacturer === 'EATON' && item.bmecatCode === 'MD04') {
                                item.sourcePath = `https://datasheet.eaton.com/Eaton-${file[i]['SUPPLIER_PID'][0]}-${file[i]['PRODUCT_DETAILS'][0]['MANUFACTURER_TYPE_DESCR'][0].replace(/\//gi, "-")}-en_GB.pdf?model=${file[i]['SUPPLIER_PID'][0]}&locale=en_GB&type=pdf`
                            }
                            else if (this.state.selectedManufacturer === 'SCAME' && item.bmecatCode === 'MD22') {
                                item.sourcePath = `https://www.scame.com/en/web/scame-global/catalogue/-/tech-sheet/product?productCode=${file[i]['SUPPLIER_PID'][0]}`
                            }

                            if (!item.sourcePath.includes('http') && path) {
                                item.sourcePath = (path + item.sourcePath)
                            }

                            if (item.assetType === '360view') {
                                view360.push(item)
                            } else {
                                data.push(item)
                            }
                        }
                    }
                }
                catch (err) {
                    console.log(file[i]['SUPPLIER_PID'][0] + ': ' + err)
                }
                // If we have 360view files, download them seperately - they are big
                if (view360.length > 0) {
                    try {
                        promises.push(axios.post(assetsUrl, { assets: view360, s3: this.state.assetS3 }))
                        view360 = []
                    }
                    catch (err) {
                        console.log(err)
                    }
                }

                // If we have 10 assets call lambda to download and upload them to s3
                if (data.length >= 10) {
                    try {
                        promises.push(axios.post(assetsUrl, { assets: data, s3: this.state.assetS3 }))
                        await this.sleep(50)
                        data = []
                    }
                    catch (err) {
                        console.log(err)
                    }
                }
                if (promises.length >= 5) {
                    let resp = await Promise.allSettled(promises)
                    for (let i = 0; i < resp.length; i++) {
                        if (resp[i].status === 'fulfilled' && resp[i].value.data.failedCount > 0) {
                            //let temp = this.state.failed
                            //temp.push(...resp[i].value.data.failed)
                            this.setState((prev) => ({
                                failed: [...prev.failed, ...resp[i].value.data.failed]
                            }))
                        }
                    }
                    promises = []
                }
            }
            if (data.length > 0) {
                try {
                    promises.push(axios.post(assetsUrl, { assets: data, s3: this.state.assetS3 }))
                    data = []
                }
                catch (err) {
                    console.log(err)
                }
            }
            if (promises.length > 0) {
                let resp = await Promise.allSettled(promises)
                for (let i = 0; i < resp.length; i++) {
                    if (resp[i].status === 'fulfilled' && resp[i].value.data.failedCount > 0) {
                        //let temp = this.state.failed
                        //temp.push(...resp[i].value.data.failed)
                        //console.log(...resp[i].value.data.failed)
                        this.setState((prev) => ({
                            failed: [...prev.failed, ...resp[i].value.data.failed]
                        }))
                    }
                }
            }

            this.setState({ assets: skipped })
            resolve()
        })
    }

    getFile = (url) => {
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getSignedUrl = (bucket, fileName, action) => {
        return new Promise((resolve, reject) => {
            axios.post("https://30bevcw8tk.execute-api.eu-central-1.amazonaws.com/getSignedUrl", {
                bucket: bucket,
                file: fileName,
                action: action
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    componentDidMount = async () => {

    }

    render() {
        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <hr />
                        <form>
                            <div className="form-group mb-3">
                                <label htmlFor="bmecatFile">BMECat file: </label>
                                <select className="form-control" id="bmecatFile" onChange={this.handleChangeBMECatFile}>
                                    <option value="">Select BMECat file...</option>
                                    {this.props.bmecatFiles
                                        .map(bmecat => {
                                            return (<option key={bmecat} value={bmecat}>{bmecat}</option>)
                                        })}
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="manufacturer">Manufacturer: </label>
                                <select className="form-control" id="manufacturer" onChange={this.handleChangeManufacturer}>
                                    <option value="">Select manufacturer...</option>
                                    {this.props.manufacturers
                                        .filter(manufacturer => 'code' in manufacturer && 'name' in manufacturer)
                                        .map(manufacturer => {
                                            return (<option key={manufacturer.id} value={manufacturer.code}>{manufacturer.name}</option>)
                                        })}
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="path">Assets URL path (optional)</label>
                                <input type="text" className="form-control" id="path" placeholder="https://example.com" onChange={this.handlePathChange} />
                            </div>
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="assetS3" onChange={this.handleAssetS3Change} checked={this.state.assetS3} />
                                <label className='form-check-label' htmlFor="assetS3">Assets in S3</label>
                            </div>
                            <button className="btn btn-primary mb-2" onClick={this.onSubmit} disabled={this.state.loading}>Get assets!</button>
                        </form>
                    </div>
                    <div className="col-sm-3">
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="card">
                            <div className="card-header bg-info text-white">
                                URL Path examples
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Siemens:</strong> http://siemens.metelserver.com</li>
                                <li className="list-group-item"><strong>Legrand:</strong> http://docdif.fr.grpleg.com/general</li>
                                <li className="list-group-item"><strong>ABB:</strong> https://cdn.productimages.abb.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>
                {this.state.loading && (
                    <div className="row justify-content-center mb-2">
                        <div className="spinner-border text-primary mt-3 ml-3" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {/*                {this.state.assets.length > 0 && (
                    <div className="row justify-content-center mb-2">
                        <div className="col-sm-8">
                            {this.state.assets
                                .filter(asset => asset.length > 0)
                                .map((asset, count) => (<span key={count}>{asset}<br /></span>))}
                        </div>
                    </div>
                )} */}
                {this.state.failed.length > 0 && (
                    <div className="row justify-content-center mb-2">
                        <div className="col-sm-8">
                            {this.state.failed
                                .map((asset, count) => (<span key={count}>{count}: Entity ID: {asset.entityId}, Asset type: {asset.assetType}, Source path: {asset.sourcePath}<br /></span>))}
                        </div>
                    </div>
                )}
            </React.Fragment >
        )
    }
}

export default Assets