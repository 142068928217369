import * as React from 'react'
import axios from 'axios'

const size = 20 // TREBA STAVITI 20, MAX je oko 750
const fullProductURL = 'https://2pq1cuk8h1.execute-api.eu-central-1.amazonaws.com/makeFullProduct/' + size
var haveRows = true
var cnt = 0

class FullProduct extends React.Component {
    state = { loading: false, bmecatCount: 0, navCount: 0, readCapacityCount: 0, writeCapacityCount: 0, lastItem: { "SK": "1610915233000", "productId": "1692248" } }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    onSubmit = async (e) => {
        e.preventDefault()

        this.setState({
            loading: true,
            navCount: 0,
            bmecatCount: 0,
            readCapacityCount: 0,
            writeCapacityCount: 0,
            lastItem: { "SK": "1610915233000", "productId": "1692248" }
        })

        try {
            while (haveRows === true) {
                cnt = cnt + 1
                let resp = await axios.post(fullProductURL, {
                    lastItem: this.state.lastItem
                })
                if ('success' in resp.data && resp.data.success === true) {
                    if (resp.data.lastItem === {}) {
                        haveRows = false
                    }
                    this.setState({
                        bmecatCount: this.state.bmecatCount + resp.data.totalBMECat,
                        navCount: this.state.navCount + resp.data.totalNAV,
                        readCapacityCount: this.state.readCapacityCount + resp.data.readCapacityUsed,
                        writeCapacityCount: this.state.writeCapacityCount + resp.data.writeCapacityUsed,
                        lastItem: resp.data.lastItem
                    })
                }
                else {
                    console.log('LastRow: ' + this.state.lastItem)
                    haveRows = false
                }
            }
        }
        catch (err) {
            console.log(err)
        }
        cnt = 0
        this.setState({
            loading: false
        })
    }



    componentDidMount = async () => {

    }

    render() {
        return (
            <React.Fragment>
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className="row justify-content-center">
                            {this.state.navCount > 0 && (
                                <div className="col-sm-6">
                                    <hr />
                                    <div className="card">
                                        <div className="card-header bg-dark text-white">
                                            RESULTS
                                        </div>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item"><strong>NAV count:</strong> {this.state.navCount}</li>
                                            <li className="list-group-item"><strong>BMECat count:</strong> {this.state.bmecatCount}</li>
                                            <li className="list-group-item"><strong>Total read capacity:</strong> {this.state.readCapacityCount}</li>
                                            <li className="list-group-item"><strong>Total write capacity:</strong> {this.state.writeCapacityCount}</li>
                                            <li className="list-group-item"><strong>Last scanned item:</strong> {JSON.stringify(this.state.lastItem)}</li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <hr />
                        Generates all products. Starts from NAV table and then populates all with bmecat data
                        <hr />
                        <form>
                            <div className="d-grid gap-2">
                                <button className="btn btn-primary" type="button" onClick={this.onSubmit} disabled={this.state.loading}>Generate full products!</button>
                            </div>
                            <i>* Don't leave this page until finish</i>
                        </form>
                        {this.state.loading && (
                            <div className="row justify-content-center mb-2">
                                <div className="spinner-border text-primary mt-3 ml-3" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-sm-4'>

                    </div>
                </div>


            </React.Fragment >
        )
    }
}

export default FullProduct